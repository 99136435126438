import { createSlice } from "@reduxjs/toolkit";
import {
  createSportAction,
  editSportAction,
  getAllSportsAction,
  deleteSportAction,
  fetchSportAction,
} from "./actions";
import { notification } from "antd";

const initialState = {
  createdSports: {},
  loadingCreate: false,
  errorCreate: null,

  editedSports: {},
  loadingEdit: false,
  errorEdit: null,

  allSports: [],
  loadingAll: false,
  errorAll: null,

  deletedEmployee: {},
  loadingDelete: false,
  errorDelete: null,

  allSportsForChild: {},
  loadingAllSportsForChild: false,
  errorForChild: null,
};
const sportsSlice = createSlice({
  name: "sports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSportAction.pending, (state) => {
        state.loadingCreate = true;
        state.errorCreate = null;
      })
      .addCase(createSportAction.fulfilled, (state, action) => {
        state.loadingCreate = false;
        state.createdUser = action.payload;
        if (action?.payload?.meta?.success === true) {
          state?.allSports?.data?.push(action.payload?.data);
        }
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.message,
            duration: 2,
          });
        }
      })
      .addCase(createSportAction.rejected, (state, action) => {
        state.loadingCreate = false;
        state.errorCreate = action.payload;
      })
      .addCase(editSportAction.pending, (state) => {
        state.loadingEdit = true;
        state.errorEdit = null;
      })
      .addCase(editSportAction.fulfilled, (state, action) => {
        state.loadingEdit = false;
        state.editedUser = action.payload;
        state.allSports.data = state?.allSports?.data.map((item) =>
          item.id === action.payload?.data?.id ? action.payload?.data : item
        );
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(editSportAction.rejected, (state, action) => {
        state.loadingEdit = false;
        state.errorEdit = action.payload;
      })

      .addCase(deleteSportAction.fulfilled, (state, action) => {
        console.log(action?.payload);
        state.loadingDelete = false;
        state.deleteSport = action.payload;
        state.allSports.data = state?.allSports?.data?.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteSportAction.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.payload;
      })
      .addCase(fetchSportAction.pending, (state) => {
        state.loadingGetAllParent = true;
        state.errorGetAllParent = null;
      })
      .addCase(fetchSportAction.fulfilled, (state, action) => {
        state.loadingGetAllParent = false;
        state.allSports = action.payload;
      })
      .addCase(fetchSportAction.rejected, (state, action) => {
        state.loadingGetAllParent = false;
        state.errorGetAllParent = null;
      })
      .addCase(getAllSportsAction.pending, (state) => {
        state.loadingAllSportsForChild = true;
        state.errorForChild = null;
      })
      .addCase(getAllSportsAction.fulfilled, (state, action) => {
        console.log("payyy", action.payload)
        state.loadingAllSportsForChild = false;
        state.allSportsForChild = action.payload;
      })
      .addCase(getAllSportsAction.rejected, (state, action) => {
        state.loadingAllSportsForChild = false;
        state.errorForChild = null;
      });
  },
});
export default sportsSlice.reducer;
