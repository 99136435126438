import { createAsyncThunk } from "@reduxjs/toolkit";
import { createEmployee, editEmployee, getAllEmployee,deleteEmployee } from "../../api/employee";

// Thunk action for get all user
export const createEmployeeAction = createAsyncThunk(
  "user/create",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await createEmployee(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for edit user
export const editEmployeeAction = createAsyncThunk(
  "user/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editEmployee(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for get all user
export const getAllEmployeeAction = createAsyncThunk(
  "user/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllEmployee(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Thunk action for delelte employee
export const deleteEmployeeAction = createAsyncThunk(
  "employee/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteEmployee(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);