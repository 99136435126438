import React, { useState, useEffect, Suspense } from "react";
import { Layout } from "antd";
import HeaderNav from "../../components/AuthLayout/HeaderNav";
import SideBar from "../../components/AuthLayout/SideBar";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import "./LayoutBox.scss"

const { Content } = Layout;

const AuthLayout = () => {
  const { isCollapse } = useSelector((state) => state.sidebarCollapse);

  return (
    <>
      <div
        className={
          isCollapse
            ? "mainLayout  h-100"
            : "mainLayout sideBarWithCollapse  h-100"
        }
        data-testid="main-layout-id"
      >
        <Layout className="h-full">
          <SideBar />
          <Layout className="site-layout">
            <HeaderNav />
            <Suspense >
            <Content
              style={{
                padding: 36,
                minHeight: 280,
                // background: "#e6f2f2"
              }}
            >
              <Outlet />
            </Content>
            </Suspense>
          </Layout>
        </Layout>
      </div>
    </>
  );
};

export default AuthLayout;
