import React, { useEffect } from "react";
import "./ProfileTab.scss";

import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  message,
  notification,
} from "antd";
import { UserOutlined, MailOutlined, AuditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAction } from "../../../services/store/GetUser/actions";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

const ProfileTab = ({ profileImageForApi }) => {
  const [form] = Form.useForm();
  const { userDetail } = useSelector((state) => state.getUser); // get the data after login

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // set the initial values of the form
  useEffect(() => {
    form.setFieldsValue({
      full_name: userDetail?.full_name,
      email: userDetail?.email,
      role: userDetail?.user_type,
    });
  }, []);

  const onFinish = (values) => {
    const formData = new FormData();
    formData.append("full_name", values?.full_name);
    formData.append("email", values?.email);
    if (profileImageForApi) {
      formData.append("photo", profileImageForApi);
    }
    const data = {
      id: userDetail?.id,
    };
    dispatch(updateUserAction({ formData, data })); // to update the profile
    // setTimeout(()=>{
    //   navigate("/")
    // },1000)
  };

  return (
    <div className="main-profiletab-box" data-testid="main-profiletab-box-id">
      <Row className="mt-2">
        <Col xs={24} className="fs-18 text-bolder">
          General
        </Col>
        <Col xs={24} className="fs-14 text-black-light">
          Your Profile Information
        </Col>
      </Row>

      <div className="mt-2 main-profile-tab-form">
        <Form name="basic" onFinish={onFinish} autoComplete="off" form={form}>
          <Row className="mt-5">
            <Col xs={24} md={8} className="text-bolder">
              Name
            </Col>
            <Col xs={24} md={16} className="">
              <Form.Item
                name="full_name"
                rules={[{ required: true, message: "Please enter your name!" }]}
              >
                <Input
                  size="large"
                  placeholder="Enter Name"
                  prefix={<UserOutlined className="pl-1 pr-2" />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider className="mt-2" />

          <Row className="mt-5">
            <Col xs={24} md={8} className="text-bolder">
              Email
            </Col>
            <Col xs={24} md={16} className="">
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                  {
                    type: "email",
                    message: "Please enter valid email!",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Email"
                  prefix={<MailOutlined className="pl-1 pr-2" />}
                  data-testid="email-test-id"
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider className="mt-2" />

          <Row className="mt-5">
            <Col xs={24} md={8} className="text-bolder">
              Role
            </Col>
            <Col xs={24} md={16} className="">
              <Form.Item
                name="role"
               
              >
                <Input
                  size="large"
                  placeholder="Enter Role"
                  prefix={<AuditOutlined className="pl-1 pr-2" />}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="mt-5" justify={"end"} gutter={24}>
            <Col xs={12} md={4} className=""></Col>
            <Col xs={12} md={4} className="">
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  data-testid="update-btn-test-id"
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ProfileTab;
