import { get, post, put, del } from "../../helpers/apiWrapper";

export const createChild = async (childData) => {
  const config = {
    data: childData,
  };
  return await post(`/child/create`, config);
};

// patch API for Edit USer
export const editChild = async ({ formData, data }) => {
  const config = {
    data: formData,
  };
  return await put(`/child/edit/${data?.id}`, config);
};

// get API for get all user
export const getChildByParentId = async (data) => {
  const config = {
    params: data,
  };

  return await get(`/user/${config.params}`);
};

// delete Employee
export const deleteChild = async (data) => {
  const config = {
    data: data,
  };
  return await del(`/child/${data?.id}`, config);
};

export const getAllSchool = async () => {
  return await get(`/child/get-school`);
};

export const getIndivisualChildDetail = async (data) => {
  return await get(`/child/${data?.id}`);
};
