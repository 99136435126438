import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createSport,
  editSport,
  getAllSports,
  deleteSport,
} from "../../api/sports";

// Thunk action for get all user
export const createSportAction = createAsyncThunk(
  "sports/create",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await createSport(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for edit user
export const editSportAction = createAsyncThunk(
  "sports/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editSport(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for get all user
export const getAllSportsAction = createAsyncThunk(
  "sports/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllSports();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for delelte sports
export const deleteSportAction = createAsyncThunk(
  "sports/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteSport(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSportAction = createAsyncThunk(
  "get/parent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllSports(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
