import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createChild,
  deleteChild,
  editChild,
  getChildByParentId,
  getAllSchool,
  getIndivisualChildDetail

} from "../../api/child";

export const getChildAction = createAsyncThunk(
  "child/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getChildByParentId(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createChildAction = createAsyncThunk(
  "child/create",
  async (childData, { rejectWithValue }) => {
    try {
      const response = await createChild(childData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteChildAction = createAsyncThunk(
  "child/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteChild(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllSchoolAction = createAsyncThunk(
  "school/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllSchool();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getChildDetailAction=createAsyncThunk(
  "child/detail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getIndivisualChildDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)
