import React, { Suspense } from "react";
import "./App.scss";
import { Routes, Route, Navigate } from "react-router-dom";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PrivateRoutes, PublicRoutes } from "./routes";
import NonAuth from "./components/NonAuthLayout";
import AuthLayout from "./components/AuthLayout";
import { fetchUser } from "./services/store/GetUser/actions";
function App() {
  let location = useLocation();
  let pathname = location?.pathname?.split("/")[1];
  const dispatch = useDispatch();

  let title = pathname
    ? pathname.charAt(0).toUpperCase() + pathname.slice(1)
    : "";

  const { isDark } = useSelector((state) => state.darkMode);
  const { userDetail } = useSelector((state) => state.getUser);

  useEffect(() => {
    document.title = title ? title : "Dashboard";
  }, [title]);
  const token = localStorage.getItem("BASELINE_TOKEN");
  const isLoggedIn = token ? true : false;
  // const isLoggedIn = true;

  const userData=JSON.parse(localStorage?.getItem("data"))
  let routes=[]
  if(userData){
    const permissions = userData?.role?.permissions;
    const slugs = permissions?.map((permission) => permission.slug);
    console.log(slugs,"slugssss")
  
    routes= PrivateRoutes?.filter((item) => {
      return item?.access?.some((element) => slugs?.includes(element));
    });
  }
  

  return (
    <Routes>
      {PublicRoutes.map(({ path, exact, component: Component }) => (
        <Route
          key={path}
          path={path}
          exact={exact}
          element={
            <Suspense fallback="">
              <NonAuth>
                <Component />
              </NonAuth>
            </Suspense>
          }
        />
      ))}
      <Route path="/" exact element={<AuthLayout />}>
        {routes?.map(({ path, exact, component: Component, access }) => {
          return isLoggedIn ? (
            <Route
              key={path}
              path={path}
              exact={exact}
              element={<Component />}
            />
          ) : (
            <Route
              key={path}
              path={path}
              exact={exact}
              element={<Navigate to="/login" />}
            />
          );
        })}
      </Route>
    </Routes>
  );
}

export default App;
