import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllParent,
  createParent,
  createChild,
  deleteParent,
} from "../../api/parent&child";

export const fetchParent = createAsyncThunk(
  "get/parent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllParent(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createParentAction = createAsyncThunk(
  "parent/create",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await createParent(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createChildAction = createAsyncThunk(
  "child/create",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await createParent(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteParentAction = createAsyncThunk(
  "parent/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteParent(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
