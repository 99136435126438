import { lazy } from "react";

const PublicRoutes = [
  {
    path: "/login",
    component: lazy(() => import("../pages/SignIn/index.js")),
    exact: true,
  },

  {
    path: "/forgotPassword",
    component: lazy(() => import("../pages/ForgetPassword/index.js")),
  },
  {
    path: "/forgotPassword/:id",
    component: lazy(() => import("../pages/Newpassword")),
  },
  {
    path: "*",
    component: lazy(() => import("../pages/Page404")),
    exact: true,
  },

  // All the public routes
];

const PrivateRoutes = [
  {
    path: "/",
    component: lazy(() => import("../pages/Dashboard")),
    exact: true,
    access: ["view-dashboard", "delete-dashboard"],
  },
  
  {
    path: "/employee",
    component: lazy(() => import("../pages/UserManegment")),
    exact: true,
    access: [
      "view-employee-management",
      "create-update-employee-management",
      "delete-employee-management",
    ],
  },

  {
    path: "/roles",
    component: lazy(() => import("../pages/Roles")),
    exact: true,
    access: [
      "view-roles-permissions",
      "create-update-roles-permission",
      "delete-roles-permissions",
    ],
  },
  {
    path: "/parentchildManagement",
    component: lazy(() => import("../pages/ParentchildManagement/Parent")),
    access: [
      "view-parent-child",
      "create-update-parent-child",
      "delete-parent-child",
    ],
  },
  {
    path: "/sports",
    component: lazy(() => import("../pages/SportsManagment")),
    exact: true,
    access: [
      "view-sports-management",
      "create-update-sports-management",
      "delete-sports-management",
    ],
  },

  {
    path: "/addrole",
    component: lazy(() => import("../pages/AddRole")),
    access: ["create-update-roles-permission"],
  },
  {
    path: "/editrole/:id",
    component: lazy(() => import("../pages/AddRole")),
    access: ["create-update-roles-permission"],
  },
  //parent list
  
  // parent list add
  {
    path: "/parentchildManagement/add",
    component: lazy(() =>
      import("../pages/ParentchildManagement/ParentDetails")
    ),
    access: ["create-update-parent-child"],
  },
  // parent list edit
  {
    path: "/parentchildManagement/edit/:id",
    component: lazy(() =>
      import("../pages/ParentchildManagement/ParentDetails")
    ),
    access: ["create-update-parent-child"],
  },
  //child edit page
  {
    path: "/parentchildManagement/:id/editchild/:id",
    component: lazy(() =>
      import("../pages/ParentchildManagement/ChildDetails")
    ),
    access: ["create-update-parent-child"],
  },

  //child add page
  {
    path: "/parentchildManagement/:id/addchild",
    component: lazy(() =>
      import("../pages/ParentchildManagement/ChildDetails")
    ),
    access: ["create-update-parent-child"],
  },

  //child detail page
  {
    path: "/parentchildManagement/:id/child/:id/details",
    component: lazy(() =>
      import("../pages/ParentchildManagement/ChildAllDetails")
    ),
    access: ["view-parent-child", "create-update-parent-child"],
  },
  
  {
    path: "/profile",
    component: lazy(() => import("../pages/Profile")),
    exact: true,
    access: [
      "view-dashboard",
      "delete-dashboard",
      "view-employee-management",
      "create-update-employee-management",
      "delete-employee-management",
      "view-roles-permissions",
      "create-update-roles-permission",
      "delete-roles-permissions",
      "view-parent-child",
      "create-update-parent-child",
      "delete-parent-child",
      "view-sports-management",
      "create-update-sports-management",
      "delete-sports-management",
    ],
  },

  // All the private route
];

export { PublicRoutes, PrivateRoutes };
