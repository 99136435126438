import { createSlice } from "@reduxjs/toolkit";
import {
  createEmployeeAction,
  editEmployeeAction,
  getAllEmployeeAction,
  deleteEmployeeAction,
} from "./actions";
import { notification } from "antd";

const initialState = {
  createdEmployee: {},
  loadingCreate: false,
  errorCreate: null,

  editedUser: {},
  loadingEdit: false,
  errorEdit: null,

  allEmployee: [],
  loadingAll: false,
  errorAll: null,

  deletedEmployee: {},
  loadingDelete: false,
  errorDelete: null,
};
const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createEmployeeAction.pending, (state) => {
        state.loadingCreate = true;
        state.errorCreate = null;
      })
      .addCase(createEmployeeAction.fulfilled, (state, action) => {
        state.loadingCreate = false;
        state.createdEmployee = action.payload;
        if (action?.payload?.meta?.success === true) {
          state?.allEmployee?.data?.push(action.payload?.data);
          state.allEmployee.meta.pagination.totalEmployees += 1; 
        }
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(createEmployeeAction.rejected, (state, action) => {
        state.loadingCreate = false;
        state.errorCreate = action.payload;
      })
      .addCase(editEmployeeAction.pending, (state) => {
        state.loadingEdit = true;
        state.errorEdit = null;
      })
      .addCase(editEmployeeAction.fulfilled, (state, action) => {
        
        state.loadingEdit = false;
        state.editedUser = action.payload;
        state.allEmployee.data = state.allEmployee.data.map((macro) =>
          macro.id === action.payload?.data?.id ? action.payload?.data : macro
        );
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(editEmployeeAction.rejected, (state, action) => {
        state.loadingEdit = false;
        state.errorEdit = action.payload;
      })
      .addCase(getAllEmployeeAction.pending, (state) => {
        state.loadingAll = true;
        state.errorAll = null;
      })
      .addCase(getAllEmployeeAction.fulfilled, (state, action) => {
        state.loadingAll = false;
        state.allEmployee = action.payload;
       
      })
      .addCase(getAllEmployeeAction.rejected, (state, action) => {
        state.loadingAll = false;
        state.errorAll = action.payload;
      })
      .addCase(deleteEmployeeAction.pending, (state) => {
        state.loadingDelete = true;
        state.errorDelete = null;
      })
      .addCase(deleteEmployeeAction.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.loadingDelete = false;
        state.deletedEmployee = action.payload;
        state.allEmployee.data = state.allEmployee.data.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        state.allEmployee.meta.pagination.totalEmployees -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteEmployeeAction.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.payload;
      });
  },
});
export default employeeSlice.reducer;
