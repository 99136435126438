import { Button } from "antd";
import {
  AccountBookFilled,
  AccountBookOutlined,
  AccountBookTwoTone,
  AimOutlined,
  AlertFilled,
  AlertOutlined,
  AlertTwoTone,
  AlibabaOutlined,
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  AlipayCircleFilled,
  AlipayCircleOutlined,
  AlipayOutlined,
  AlipaySquareFilled,
  AliwangwangFilled,
  AliwangwangOutlined,
  AliyunOutlined,
  AmazonCircleFilled,
  AmazonOutlined,
  AmazonSquareFilled,
  AndroidFilled,
  AndroidOutlined,
  AntCloudOutlined,
  AntDesignOutlined,
  ApartmentOutlined,
  ApiFilled,
  ApiOutlined,
  ApiTwoTone,
  AppleFilled,
  AppleOutlined,
  AppstoreAddOutlined,
  AppstoreFilled,
  AppstoreTwoTone,
  AreaChartOutlined,
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  ArrowsAltOutlined,
  AudioFilled,
  AudioMutedOutlined,
  AudioOutlined,
  AudioTwoTone,
  AuditOutlined,
  BackwardFilled,
  BackwardOutlined,
  BankFilled,
  BankOutlined,
  BankTwoTone,
  BarChartOutlined,
  BarcodeOutlined,
  BarsOutlined,
  BehanceCircleFilled,
  BehanceOutlined,
  BehanceSquareFilled,
  BehanceSquareOutlined,
  BellFilled,
  BellOutlined,
  BellTwoTone,
  BgColorsOutlined,
  BlockOutlined,
  BoldOutlined,
  BookFilled,
  BookOutlined,
  BookTwoTone,
  BorderBottomOutlined,
  BorderHorizontalOutlined,
  BorderInnerOutlined,
  BorderLeftOutlined,
  BorderOuterOutlined,
  BorderOutlined,
  BorderRightOutlined,
  BorderTopOutlined,
  BorderVerticleOutlined,
  BorderlessTableOutlined,
  BoxPlotFilled,
  BoxPlotOutlined,
  BoxPlotTwoTone,
  BranchesOutlined,
  BugFilled,
  BugOutlined,
  BugTwoTone,
  BuildFilled,
  BuildOutlined,
  BuildTwoTone,
  BulbFilled,
  BulbOutlined,
  BulbTwoTone,
  CalculatorFilled,
  CalculatorOutlined,
  CalculatorTwoTone,
  CalendarFilled,
  CalendarOutlined,
  CalendarTwoTone,
  CameraFilled,
  CameraOutlined,
  CameraTwoTone,
  CarFilled,
  CarOutlined,
  CarTwoTone,
  CaretDownFilled,
  CaretDownOutlined,
  CaretLeftFilled,
  CaretLeftOutlined,
  CaretRightFilled,
  CaretRightOutlined,
  CaretUpFilled,
  CaretUpOutlined,
  CarryOutFilled,
  CarryOutOutlined,
  CarryOutTwoTone,
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  CheckOutlined,
  CheckSquareFilled,
  CheckSquareOutlined,
  CheckSquareTwoTone,
  ChromeFilled,
  ChromeOutlined,
  CiCircleFilled,
  CiCircleOutlined,
  CiCircleTwoTone,
  CiOutlined,
  CiTwoTone,
  ClearOutlined,
  ClockCircleFilled,
  ClockCircleOutlined,
  ClockCircleTwoTone,
  CloseCircleFilled,
  CloseCircleOutlined,
  CloseCircleTwoTone,
  CloseOutlined,
  CloseSquareFilled,
  CloseSquareOutlined,
  CloudDownloadOutlined,
  CloudFilled,
  CloudOutlined,
  CloudServerOutlined,
  CloudSyncOutlined,
  CloudTwoTone,
  CloudUploadOutlined,
  ClusterOutlined,
  CodeOutlined,
  CodeSandboxCircleFilled,
  CodeSandboxOutlined,
  CodeSandboxSquareFilled,
  CodeTwoTone,
  CodepenCircleFilled,
  CodepenCircleOutlined,
  CodepenOutlined,
  CodepenSquareFilled,
  CoffeeOutlined,
  ColumnHeightOutlined,
  ColumnWidthOutlined,
  CommentOutlined,
  CompassFilled,
  CompassOutlined,
  CompassTwoTone,
  CompressOutlined,
  ConsoleSqlOutlined,
  ContactsFilled,
  ContactsOutlined,
  ContactsTwoTone,
  ContainerFilled,
  ContainerOutlined,
  ContainerTwoTone,
  ControlFilled,
  ControlOutlined,
  ControlTwoTone,
  CopyFilled,
  CopyOutlined,
  CopyTwoTone,
  CopyrightOutlined,
  CopyrightTwoTone,
  CreditCardFilled,
  CreditCardOutlined,
  CreditCardTwoTone,
  CrownFilled,
  CrownOutlined,
  CrownTwoTone,
  CustomerServiceFilled,
  CustomerServiceOutlined,
  CustomerServiceTwoTone,
  DashOutlined,
  DashboardFilled,
  DashboardOutlined,
  DashboardTwoTone,
  DatabaseFilled,
  DatabaseOutlined,
  DatabaseTwoTone,
  DeleteColumnOutlined,
  DeleteFilled,
  DeleteOutlined,
  DeleteRowOutlined,
  DeleteTwoTone,
  DeliveredProcedureOutlined,
  DeploymentUnitOutlined,
  DesktopOutlined,
  DiffFilled,
  DiffOutlined,
  DiffTwoTone,
  DingdingOutlined,
  DingtalkCircleFilled,
  DingtalkOutlined,
  DingtalkSquareFilled,
  DisconnectOutlined,
  DislikeFilled,
  DislikeOutlined,
  DislikeTwoTone,
  DollarOutlined,
  DollarTwoTone,
  DotChartOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  DownCircleFilled,
  DownCircleOutlined,
  DownCircleTwoTone,
  DownOutlined,
  DownSquareFilled,
  DownSquareOutlined,
  DownSquareTwoTone,
  DownloadOutlined,
  DragOutlined,
  DribbbleCircleFilled,
  DribbbleOutlined,
  DribbbleSquareFilled,
  DribbbleSquareOutlined,
  DropboxCircleFilled,
  DropboxOutlined,
  DropboxSquareFilled,
  EditFilled,
  EditOutlined,
  EditTwoTone,
  EllipsisOutlined,
  EnterOutlined,
  EnvironmentFilled,
  EnvironmentOutlined,
  EnvironmentTwoTone,
  EuroCircleFilled,
  EuroCircleOutlined,
  EuroCircleTwoTone,
  EuroOutlined,
  EuroTwoTone,
  ExceptionOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
  ExclamationOutlined,
  ExpandAltOutlined,
  ExpandOutlined,
  ExperimentFilled,
  ExperimentOutlined,
  ExperimentTwoTone,
  ExportOutlined,
  EyeFilled,
  EyeInvisibleFilled,
  EyeInvisibleOutlined,
  EyeInvisibleTwoTone,
  EyeOutlined,
  EyeTwoTone,
  FacebookFilled,
  FacebookOutlined,
  FallOutlined,
  FastBackwardFilled,
  FastBackwardOutlined,
  FastForwardFilled,
  FastForwardOutlined,
  FieldBinaryOutlined,
  FieldNumberOutlined,
  FieldStringOutlined,
  FieldTimeOutlined,
  FileAddFilled,
  FileAddOutlined,
  FileAddTwoTone,
  FileDoneOutlined,
  FileExcelFilled,
  FileExcelOutlined,
  FileExcelTwoTone,
  FileExclamationFilled,
  FileExclamationOutlined,
  FileExclamationTwoTone,
  FileFilled,
  FileGifOutlined,
  FileImageFilled,
  FileImageOutlined,
  FileImageTwoTone,
  FileJpgOutlined,
  FileMarkdownFilled,
  FileMarkdownOutlined,
  FileMarkdownTwoTone,
  FileOutlined,
  FilePdfFilled,
  FilePdfOutlined,
  FilePdfTwoTone,
  FilePptFilled,
  FilePptOutlined,
  FilePptTwoTone,
  FileProtectOutlined,
  FileSearchOutlined,
  FileSyncOutlined,
  FileTextFilled,
  FileTextOutlined,
  FileTextTwoTone,
  FileTwoTone,
  FileUnknownFilled,
  FileUnknownOutlined,
  FileUnknownTwoTone,
  FileWordFilled,
  FileWordOutlined,
  FileWordTwoTone,
  FileZipFilled,
  FileZipOutlined,
  FileZipTwoTone,
  FilterFilled,
  FilterOutlined,
  FilterTwoTone,
  FireFilled,
  FireOutlined,
  FireTwoTone,
  FlagFilled,
  FlagOutlined,
  FlagTwoTone,
  FolderAddFilled,
  FolderAddOutlined,
  FolderAddTwoTone,
  FolderFilled,
  FolderOpenFilled,
  FolderOpenOutlined,
  FolderOpenTwoTone,
  FolderOutlined,
  FolderTwoTone,
  FolderViewOutlined,
  FontColorsOutlined,
  FontSizeOutlined,
  ForkOutlined,
  FormOutlined,
  FormatPainterFilled,
  FormatPainterOutlined,
  ForwardFilled,
  ForwardOutlined,
  FrownFilled,
  FrownOutlined,
  FrownTwoTone,
  FullscreenExitOutlined,
  FullscreenOutlined,
  FunctionOutlined,
  FundFilled,
  FundOutlined,
  FundProjectionScreenOutlined,
  FundTwoTone,
  FundViewOutlined,
  FunnelPlotFilled,
  FunnelPlotOutlined,
  FunnelPlotTwoTone,
  GatewayOutlined,
  GifOutlined,
  GiftFilled,
  GiftOutlined,
  GiftTwoTone,
  GithubFilled,
  GithubOutlined,
  GitlabFilled,
  GitlabOutlined,
  GlobalOutlined,
  GoldFilled,
  GoldOutlined,
  GoldTwoTone,
  GoldenFilled,
  GoogleCircleFilled,
  GoogleOutlined,
  GooglePlusCircleFilled,
  GooglePlusOutlined,
  GoogleSquareFilled,
  GroupOutlined,
  HddFilled,
  HddOutlined,
  HddTwoTone,
  HeartFilled,
  HeartOutlined,
  HeartTwoTone,
  HeatMapOutlined,
  HighlightFilled,
  HighlightOutlined,
  HighlightTwoTone,
  HistoryOutlined,
  HolderOutlined,
  HomeFilled,
  HomeOutlined,
  HomeTwoTone,
  HourglassFilled,
  HourglassOutlined,
  HourglassTwoTone,
  Html5Filled,
  Html5Outlined,
  Html5TwoTone,
  IdcardFilled,
  IdcardOutlined,
  IdcardTwoTone,
  IeCircleFilled,
  IeOutlined,
  IeSquareFilled,
  ImportOutlined,
  InboxOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  InfoCircleTwoTone,
  InfoOutlined,
  InsertRowAboveOutlined,
  InsertRowBelowOutlined,
  InsertRowLeftOutlined,
  InsertRowRightOutlined,
  InstagramFilled,
  InstagramOutlined,
  InsuranceFilled,
  InsuranceOutlined,
  InsuranceTwoTone,
  InteractionFilled,
  InteractionOutlined,
  InteractionTwoTone,
  IssuesCloseOutlined,
  ItalicOutlined,
  KeyOutlined,
  LaptopOutlined,
  LayoutFilled,
  LayoutOutlined,
  LayoutTwoTone,
  LeftCircleFilled,
  LeftCircleOutlined,
  LeftCircleTwoTone,
  LeftOutlined,
  LeftSquareFilled,
  LeftSquareOutlined,
  LeftSquareTwoTone,
  LikeFilled,
  LikeOutlined,
  LikeTwoTone,
  LineChartOutlined,
  LineHeightOutlined,
  LineOutlined,
  LinkOutlined,
  LinkedinFilled,
  LinkedinOutlined,
  Loading3QuartersOutlined,
  LockFilled,
  LockOutlined,
  LockTwoTone,
  LoginOutlined,
  LogoutOutlined,
  MacCommandFilled,
  MacCommandOutlined,
  MailFilled,
  MailOutlined,
  MailTwoTone,
  ManOutlined,
  MedicineBoxFilled,
  MedicineBoxOutlined,
  MedicineBoxTwoTone,
  MediumCircleFilled,
  MediumOutlined,
  MediumSquareFilled,
  MediumWorkmarkOutlined,
  MehFilled,
  MehOutlined,
  MehTwoTone,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  MergeCellsOutlined,
  MessageFilled,
  MessageOutlined,
  MessageTwoTone,
  MinusCircleFilled,
  MinusCircleOutlined,
  MinusCircleTwoTone,
  MinusOutlined,
  MinusSquareFilled,
  MinusSquareOutlined,
  MinusSquareTwoTone,
  MobileFilled,
  MobileOutlined,
  MobileTwoTone,
  MoneyCollectFilled,
  MoneyCollectOutlined,
  MoneyCollectTwoTone,
  MonitorOutlined,
  MoreOutlined,
  NodeCollapseOutlined,
  NodeExpandOutlined,
  NodeIndexOutlined,
  NotificationFilled,
  NotificationOutlined,
  NotificationTwoTone,
  NumberOutlined,
  OneToOneOutlined,
  OrderedListOutlined,
  PaperClipOutlined,
  PartitionOutlined,
  PauseCircleFilled,
  PauseCircleOutlined,
  PauseCircleTwoTone,
  PauseOutlined,
  PayCircleFilled,
  PayCircleOutlined,
  PercentageOutlined,
  PhoneFilled,
  PhoneOutlined,
  PhoneTwoTone,
  PicCenterOutlined,
  PicLeftOutlined,
  PicRightOutlined,
  PictureFilled,
  PictureOutlined,
  PictureTwoTone,
  PieChartFilled,
  PieChartOutlined,
  PieChartTwoTone,
  PlayCircleFilled,
  PlayCircleOutlined,
  PlayCircleTwoTone,
  PlaySquareFilled,
  PlaySquareOutlined,
  PlaySquareTwoTone,
  PlusCircleFilled,
  PlusCircleOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  PlusSquareFilled,
  PlusSquareOutlined,
  PlusSquareTwoTone,
  PoundCircleFilled,
  PoundCircleOutlined,
  PoundCircleTwoTone,
  PoundOutlined,
  PoweroffOutlined,
  PrinterFilled,
  PrinterOutlined,
  PrinterTwoTone,
  ProfileFilled,
  ProfileOutlined,
  ProfileTwoTone,
  ProjectFilled,
  ProjectOutlined,
  ProjectTwoTone,
  PropertySafetyFilled,
  PropertySafetyOutlined,
  PropertySafetyTwoTone,
  PullRequestOutlined,
  PushpinFilled,
  PushpinOutlined,
  PushpinTwoTone,
  QqCircleFilled,
  QqOutlined,
  QqSquareFilled,
  QrcodeOutlined,
  QuestionCircleFilled,
  QuestionCircleOutlined,
  QuestionCircleTwoTone,
  QuestionOutlined,
  RadarChartOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusSettingOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
  ReadFilled,
  ReadOutlined,
  ReconciliationFilled,
  ReconciliationOutlined,
  ReconciliationTwoTone,
  RedEnvelopeFilled,
  RedEnvelopeOutlined,
  RedEnvelopeTwoTone,
  RedditCircleFilled,
  RedditOutlined,
  RedditSquareFilled,
  RedoOutlined,
  ReloadOutlined,
  RestFilled,
  RestOutlined,
  RestTwoTone,
  RetweetOutlined,
  RightCircleFilled,
  RightCircleOutlined,
  RightCircleTwoTone,
  RightOutlined,
  RightSquareFilled,
  RightSquareOutlined,
  RightSquareTwoTone,
  RiseOutlined,
  RobotFilled,
  RobotOutlined,
  RocketFilled,
  RocketOutlined,
  RocketTwoTone,
  RollbackOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SafetyCertificateFilled,
  SafetyCertificateOutlined,
  SafetyCertificateTwoTone,
  SafetyOutlined,
  SaveFilled,
  SaveOutlined,
  SaveTwoTone,
  ScanOutlined,
  ScheduleFilled,
  ScheduleTwoTone,
  ScissorOutlined,
  SecurityScanFilled,
  SecurityScanTwoTone,
  SendOutlined,
  SettingFilled,
  SettingOutlined,
  SettingTwoTone,
  ShakeOutlined,
  ShareAltOutlined,
  ShopFilled,
  ShopOutlined,
  ShopTwoTone,
  ShoppingCartOutlined,
  ShoppingFilled,
  ShoppingOutlined,
  ShoppingTwoTone,
  ShrinkOutlined,
  SignalFilled,
  SisternodeOutlined,
  SketchCircleFilled,
  SketchSquareFilled,
  SkinFilled,
  SkinOutlined,
  SkinTwoTone,
  SkypeFilled,
  SkypeOutlined,
  SlackCircleFilled,
  SlackOutlined,
  SlackSquareFilled,
  SlackSquareOutlined,
  SlidersFilled,
  SlidersOutlined,
  SlidersTwoTone,
  SmallDashOutlined,
  SmileFilled,
  SmileOutlined,
  SmileTwoTone,
  SnippetsFilled,
  SnippetsOutlined,
  SnippetsTwoTone,
  SolutionOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  SoundFilled,
  SoundOutlined,
  SoundTwoTone,
  SplitCellsOutlined,
  StarFilled,
  StarOutlined,
  StarTwoTone,
  StepBackwardFilled,
  StepBackwardOutlined,
  StepForwardFilled,
  StepForwardOutlined,
  StockOutlined,
  StopFilled,
  StopOutlined,
  StopTwoTone,
  StrikethroughOutlined,
  SubnodeOutlined,
  SwapLeftOutlined,
  SwapOutlined,
  SwapRightOutlined,
  SwitcherFilled,
  SwitcherOutlined,
  SwitcherTwoTone,
  SyncOutlined,
  TableOutlined,
  TabletFilled,
  TabletOutlined,
  TabletTwoTone,
  TagFilled,
  TagOutlined,
  TagTwoTone,
  TagsFilled,
  TagsOutlined,
  TagsTwoTone,
  TaobaoCircleFilled,
  TaobaoCircleOutlined,
  TaobaoOutlined,
  TaobaoSquareFilled,
  TeamOutlined,
  ThunderboltFilled,
  ThunderboltOutlined,
  ThunderboltTwoTone,
  ToTopOutlined,
  ToolFilled,
  ToolOutlined,
  ToolTwoTone,
  TrademarkCircleFilled,
  TrademarkCircleOutlined,
  TrademarkCircleTwoTone,
  TrademarkOutlined,
  TransactionOutlined,
  TranslationOutlined,
  TrophyFilled,
  TrophyOutlined,
  TrophyTwoTone,
  TwitterCircleFilled,
  TwitterOutlined,
  TwitterSquareFilled,
  UnderlineOutlined,
  UndoOutlined,
  UngroupOutlined,
  UnlockFilled,
  UnlockOutlined,
  UnlockTwoTone,
  UnorderedListOutlined,
  UpCircleFilled,
  UpCircleOutlined,
  UpCircleTwoTone,
  UpOutlined,
  UpSquareFilled,
  UpSquareOutlined,
  UpSquareTwoTone,
  UploadOutlined,
  UsbFilled,
  UsbOutlined,
  UsbTwoTone,
  UserAddOutlined,
  UserDeleteOutlined,
  UserOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
  VerifiedOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignTopOutlined,
  VerticalLeftOutlined,
  VerticalRightOutlined,
  VideoCameraAddOutlined,
  VideoCameraFilled,
  VideoCameraOutlined,
  VideoCameraTwoTone,
  WalletFilled,
  WalletOutlined,
  WalletTwoTone,
  WarningFilled,
  WarningOutlined,
  WarningTwoTone,
  WechatFilled,
  WechatOutlined,
  WeiboCircleFilled,
  WeiboCircleOutlined,
  WeiboOutlined,
  WeiboSquareFilled,
  WeiboSquareOutlined,
  WhatsAppOutlined,
  WifiOutlined,
  WindowsFilled,
  WindowsOutlined,
  WomanOutlined,
  YahooFilled,
  YahooOutlined,
  YoutubeFilled,
  YoutubeOutlined,
  YuqueFilled,
  YuqueOutlined,
  ZhihuCircleFilled,
  ZhihuOutlined,
  ZhihuSquareFilled,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";

export const messageNotification = [
  {
    id: 1,
    type: "message",
    title: "Message",
    time: "30 minutes",
    description: "New message from John Doe",
    message: "Hello, Any progress on the project?",
  },
  {
    id: 2,
    type: "alert",
    title: "Alert",
    time: "35 minutes",
    description: "Server overloaded 89%",
    message: "Traffic from the server is overloaded.",
  },
  {
    id: 3,
    type: "message",
    title: "Message",
    time: "1 hour",
    description: "New message from Alvero Moreno",
    message: "Hello, How are you?",
  },
  {
    id: 4,
    type: "message",
    title: "Message",
    time: "2 hour",
    description: "New message from Alvero Moreno",
    message: "Are you available?",
  },
  {
    id: 5,
    type: "alert",
    title: "Alert",
    time: "4 hour",
    description: "Server overloaded 70%",
    message: "Traffic from the server is overloaded.",
  },
  {
    id: 6,
    type: "message",
    title: "Message",
    time: "10 hour",
    description: "New message from Alvero Moreno",
    message: "Hello, How are you?",
  },
];

export const dataSource = [
  {
    key: "1",
    name: "Mike",
    gender: "Male",
    age: 32,
    address: "10 Downing Street",
  },
  {
    key: "2",
    name: "John",
    gender: "Male",
    age: 42,
    address: "10 Downing Street",
  },
  {
    key: "3",
    name: "Moreno",
    gender: "Female",
    age: 62,
    address: "10 Downing Street",
  },
  {
    key: "4",
    name: "Alvero",
    gender: "Male",
    age: 52,
    address: "10 Downing Street",
  },
  {
    key: "5",
    name: "Licaa",
    gender: "Female",
    age: 72,
    address: "10 Downing Street",
  },
];

export const dataSourcePagination = [
  {
    key: "1",
    name: "Mike",
    gender: "Male",
    age: 32,
    address: "10 Downing Street",
  },
  {
    key: "2",
    name: "John",
    gender: "Male",
    age: 42,
    address: "10 Downing Street",
  },
  {
    key: "3",
    name: "Moreno",
    gender: "Female",
    age: 62,
    address: "10 Downing Street",
  },
  {
    key: "4",
    name: "Alvero",
    gender: "Male",
    age: 52,
    address: "10 Downing Street",
  },
  {
    key: "5",
    name: "Licaa",
    gender: "Female",
    age: 72,
    address: "10 Downing Street",
  },
  {
    key: "6",
    name: "Jhon",
    gender: "Male",
    age: 52,
    address: "10 Downing Street",
  },
  {
    key: "7",
    name: "Moreno",
    gender: "Male",
    age: 62,
    address: "10 Downing Street",
  },
  {
    key: "8",
    name: "Alvero",
    gender: "Male",
    age: 72,
    address: "10 Downing Street",
  },
];

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
];
export const actionTagData1 = [
  {
    key: "1",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "2",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Inactive",
    
  },
  {
    key: "3",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "4",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "5",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "6",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "7",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "8",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "9",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "10",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "11",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  
];
export const actionTagDataForChildList = [
  {
    key: "1",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    gender: "male",
    dob: "13/02/2001",
    test:"test"
    
  },
  {
    key: "2",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    gender: "male",
    dob: "13/02/2001",
    
  },
  {
    key: "3",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    gender: "male",
    dob: "13/02/2001",
    
  },
  {
    key: "4",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    gender: "male",
    dob: "13/02/2001",
    
  },
  {
    key: "5",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    gender: "male",
    dob: "13/02/2001",
    
  },
  {
    key: "6",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    gender: "male",
    dob: "13/02/2001",
    
  },
  {
    key: "7",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    gender: "male",
    dob: "13/02/2001",
    
  },
  {
    key: "8",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    gender: "male",
    dob: "13/02/2001",
    
  },
  {
    key: "9",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    gender: "male",
    dob: "13/02/2001",
    
  },
  {
    key: "10",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    gender: "male",
    dob: "13/02/2001",
    
  },
  {
    key: "11",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    gender: "male",
    dob: "13/02/2001",
    
  },
  
];
export const actionTagDataParent = [
  {
    key: "1",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    gender: "male",
    dob: "11/03/2000",
    status: "Active",
    
  },
  {
    key: "2",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    gender: "male",
    dob: "11/03/2000",
    status: "Inactive",
    
  },
  {
    key: "3",
    name: "John Brown",
    email: "test1@gmail.com",
    gender: "male",
    dob: "11/03/2000",
    status: "Active",
    
  },
  {
    key: "4",
    name: "John Brown",
    email: "test1@gmail.com",
    gender: "male",
    dob: "11/03/2000",
    status: "Active",
    
  },
  {
    key: "5",
    name: "John Brown",
    email: "test1@gmail.com",
    gender: "male",
    dob: "11/03/2000",
    status: "Active",
    
  },
  {
    key: "6",
    name: "John Brown",
    email: "test1@gmail.com",
    gender: "male",
    dob: "11/03/2000",
    status: "Active",
    
  },
  {
    key: "7",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    gender: "male",
    dob: "11/03/2000",
    status: "Active",
    
  },
  {
    key: "8",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    gender: "male",
    dob: "11/03/2000",
    status: "Active",
    
  },
  {
    key: "9",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    gender: "male",
    dob: "11/03/2000",
    status: "Active",
    
  },
  {
    key: "10",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    gender: "male",
    dob: "11/03/2000",
    status: "Active",
    
  },
  {
    key: "11",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    gender: "male",
    dob: "11/03/2000",
    status: "Active",
    
  },
  
];
export const actionTagDataForInternalUser = [
  {
    key: "1",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    password:"dsfsdfdsfg",
    phoneNumber: "1234567890",
    role: "Admin",
    status: true
    
  },
  {
    key: "2",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    password:"dsfsdfdsfg",
    role: "Admin",
    status: true
    
  },
  {
    key: "3",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    password:"fdffds",
    phoneNumber: "1234567890",
    role: "Admin",
    status: true
    
  },
  {
    key: "4",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "5",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "6",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "7",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "8",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "9",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "10",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  {
    key: "11",
    profile: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    name: "John Brown",
    email: "test1@gmail.com",
    phoneNumber: "1234567890",
    role: "Admin",
    status: "Active",
    
  },
  
];
export const actionTagDataForRoles= [
  {
    key: "1",
    rolename: "John Brown",
    dateofcreation: "11/03/1114",
    assignedusers:"John Brown,John Doe",
    access: ['view','edit','active','inactive'],
    status: true
    
  },
  {
    key: "2",
    rolename: "John Brown",
    dateofcreation: "11/03/1114",
    assignedusers:"John Brown,John Doe",
    access: ['view','edit','active'],
    status: false
    
  },
  {
    key: "3",
    rolename: "John Brown",
    dateofcreation: "11/03/1114",
    assignedusers:"John Brown,John Doe",
    access: ['view','edit','active','inactive'],
    status: true
    
  },
  {
    key: "4",
    rolename: "John Brown",
    dateofcreation: "11/03/1114",
    assignedusers:"John Brown,John Doe",
    access: ['view','edit','active','inactive'],
    status: false
    
  },
  {
    key: "5",
    rolename: "John Brown",
    dateofcreation: "11/03/1114",
    access: ['view','edit','active','inactive'],
    assignedusers:"John Brown,John Doe",
    status: false
    
  },
  
  
  
];
export const actionTagData = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"],
    testId: "John-Brown",
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    tags: ["loser"],
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sydney No. 1 Lake Park",
    tags: ["loser", "teacher"],
  },
  {
    key: "4",
    name: "Alvero Moreno",
    age: 32,
    address: "New York No. 5 Lake Park",
    tags: ["cool", "nice"],
  },
  {
    key: "5",
    name: "Licaa Jeon",
    age: 32,
    address: "Sydney No. 10 Lake Park",
    tags: ["cool", "teacher"],
  },
  // Add more data here
  {
    key: "6",
    name: "John Doe",
    age: 28,
    address: "London No. 2 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "7",
    name: "Jane Smith",
    age: 35,
    address: "New York No. 8 Lake Park",
    tags: ["cool", "teacher"],
  },
  // Add more data here
  {
    key: "8",
    name: "Mike Johnson",
    age: 45,
    address: "Sydney No. 15 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "9",
    name: "Emily Davis",
    age: 29,
    address: "London No. 6 Lake Park",
    tags: ["cool", "teacher"],
  },
  // Add more data here
  {
    key: "10",
    name: "David Wilson",
    age: 38,
    address: "New York No. 12 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "11",
    name: "Sarah Thompson",
    age: 31,
    address: "Sydney No. 18 Lake Park",
    tags: ["cool", "teacher"],
  },
  // Add more data here
  {
    key: "12",
    name: "Chris Anderson",
    age: 40,
    address: "London No. 9 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "13",
    name: "Olivia Martinez",
    age: 27,
    address: "New York No. 14 Lake Park",
    tags: ["cool", "teacher"],
  },
  // Add more data here
  {
    key: "14",
    name: "Daniel Clark",
    age: 33,
    address: "Sydney No. 20 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "15",
    name: "Sophia Rodriguez",
    age: 30,
    address: "London No. 11 Lake Park",
    tags: ["cool", "teacher"],
  },
  // Add more data here
  {
    key: "16",
    name: "Matthew Lee",
    age: 37,
    address: "New York No. 16 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "17",
    name: "Ava Harris",
    age: 26,
    address: "Sydney No. 22 Lake Park",
    tags: ["cool", "teacher"],
  },
  // Add more data here
  {
    key: "18",
    name: "Andrew Walker",
    age: 41,
    address: "London No. 13 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "19",
    name: "Emma Lewis",
    age: 34,
    address: "New York No. 18 Lake Park",
    tags: ["cool", "teacher"],
  },
  // Add more data here
  {
    key: "20",
    name: "Joshua Turner",
    age: 39,
    address: "Sydney No. 24 Lake Park",
    tags: ["nice", "developer"],
  },
];

export const filterAndSortData = [
  {
    key: "1",
    name: "Mike",
    gender: "Male",
    age: 32,
    address: "10 Downing Street",
  },
  {
    key: "2",
    name: "John",
    gender: "Male",
    age: 42,
    address: "New York No. 1 Lake Park",
  },
  {
    key: "3",
    name: "Moreno",
    gender: "Female",
    age: 62,
    address: "Sydney No. 22 Lake Park",
  },
  {
    key: "4",
    name: "Alvero",
    gender: "Male",
    age: 52,
    address: "London No. 34 Lake Park",
  },
  {
    key: "5",
    name: "Licaa",
    gender: "Female",
    age: 72,
    address: "New York No. 54 Lake Park",
  },
];

export const combineDataSource = [
  {
    key: "1",
    name: "Mike",
    gender: "Male",
    age: 32,
    address: "10 Downing Street",
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    name: "John",
    gender: "Male",
    age: 42,
    address: "10 Downing Street",
    tags: ["cool", "teacher"],
  },
  {
    key: "3",
    name: "Moreno",
    gender: "Female",
    age: 62,
    address: "10 Downing Street",
    tags: ["cool", "nice"],
  },
  {
    key: "4",
    name: "Alvero",
    gender: "Male",
    age: 52,
    address: "10 Downing Street",
    tags: ["cool", "teacher"],
  },
  {
    key: "5",
    name: "Licaa",
    gender: "Female",
    age: 72,
    address: "10 Downing Street",
    tags: ["loser", "teacher"],
  },
  {
    key: "6",
    name: "Licaa",
    gender: "Female",
    age: 72,
    address: "10 Downing Street",
    tags: ["teacher"],
  },
  {
    key: "7",
    name: "Licaa",
    gender: "Female",
    age: 72,
    address: "10 Downing Street",
    tags: ["cool", "teacher"],
  },
  {
    key: "8",
    name: "Licaa",
    gender: "Female",
    age: 72,
    address: "10 Downing Street",
    tags: ["loser"],
  },
  {
    key: "9",
    name: "Licaa",
    gender: "Female",
    age: 72,
    address: "10 Downing Street",
    tags: ["cool", "nice", "loser"],
  },
  {
    key: "10",
    name: "Licaa",
    gender: "Female",
    age: 72,
    address: "10 Downing Street",
    tags: ["cool", "nice"],
  },
  {
    key: "11",
    name: "Licaa",
    gender: "Female",
    age: 72,
    address: "10 Downing Street",
    tags: ["cool", "teacher"],
  },
  {
    key: "12",
    name: "Licaa",
    gender: "Female",
    age: 72,
    address: "10 Downing Street",
    tags: ["cool", "teacher"],
  },
];

export const productData = [
  {
    key: "1",
    imageList: [
      "https://m.media-amazon.com/images/I/91OzzQSg47L._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/71mSPheTBeL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/51RjUeZFbTL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/51mXjNXLQeL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/41w0GNk3sCL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/91JwDPb9jFL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/81-NF9Z2MBL._SL1500_.jpg",
    ],
    name: `Sony 108 cm (43 inches) 4K Ultra HD Smart LED TV 43UQ7500PSF (Ceramic Black)`,
    price: "31990",
    description: `Resolution: 4K Ultra HD (3840x2160) | Refresh Rate: 60 hertz .Connectivity: Wi-Fi (Built-in) | 3 HDMI ports to connect set top box, Blu Ray players, gaming console | 1 USB ports to connect hard drives and other USB devices | eARC | Bluetooth 5.0 | Optical | Ethernet
        Sound: 20 Watts Output | 2.0 Ch Speaker | AI Sound (Virtual Surround 5.1 up-mix) | Auto Volume Levelling | Bluetooth Surround Ready Smart TV Features: AI ThinQ & WebOS 22 with User Profiles | Game Optimizer & Dashboard | α5 Gen5 AI Processor 4K | Unlimited OTT App Support`,
    companies: ["samsung", "sony"],
  },
  {
    key: "2",
    imageList: [
      "https://m.media-amazon.com/images/I/61ST4CMWEKL._SL1200_.jpg",
      "https://m.media-amazon.com/images/I/51Tfpuj315L._SL1200_.jpg",
      "https://m.media-amazon.com/images/I/71Rw+IYsrxL._SL1200_.jpg",
      "https://m.media-amazon.com/images/I/41t35bCleTL._SL1200_.jpg",
      "https://m.media-amazon.com/images/I/415a8wx0ATL._SL1200_.jpg",
    ],
    name: `iQOO Z6 44W by vivo (Raven Black, 4GB RAM, 128GB Storage) | 6.44" FHD+ AMOLED Display`,
    price: "14499",
    description: `44W FlashCharge Snapdragon 680 comes with octa-core architecture design that adopts the new 6nm process, CPU increased by 25% & GPU performance increased by 10% compared with Qualcomm Snapdragon 662.
        Z6 is equipped with a FHD+ AMOLED dispaly to give a truly immersive display experience with true color contrast. The display provides a 180Hz Touch Sampling Rate in the gaming sessions for an enhanced touch response.`,
    companies: ["sony", "apple", "oneplus"],
  },
  {
    key: "3",
    imageList: [
      "https://m.media-amazon.com/images/I/71eH88Nni4L._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/81b8-C-3h3L._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/71IwO8eVh4L._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/712xM1ODKrL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/71z8GyNhTRL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/716TZvrFPBL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/71Wkn8GmsAL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/81E-OgHmSNL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/81TAfy3F1cL._SL1500_.jpg",
    ],
    name: 'Samsung S15 OLED 2022, 15.6" 39.62 cm FHD OLED, Intel Core Evo i5-12500H 12th Gen, Thin and Light Laptop',
    price: "73000",
    description: `Processor: 12th Gen Intel EVO Core i5-12500H, 2.5 GHz Base Speed, 18MB Cache, Up to 4.5 GHz Max Turbo Speed, 12 Cores ( 4P+8E ), 16 Threads
        Memory: 16GB (8GB onboard + 8GB SO-DIMM) DDR4 3200MHz with | Storage: 512GB M.2 NVMe PCIe 4.0 SSD
        Display: 15.6-inch (39.62 cms), FHD (1920 x 1080) OLED 16:9 aspect ratio, 0.2ms response time, 600nits peak brightness, Anti-glare display, 100% DCI-P3 color gamut, Screen-to-body ratio: 84％, VESA CERTIFIED Display HDR True Black 600, PANTONE Validated.`,
    companies: ["samsung", "sony", "amazon"],
  },
  {
    key: "4",
    imageList: [
      "https://m.media-amazon.com/images/I/71jH20FkqzL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/61yM5iAoVkL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/61yhvQWo8gL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/818tl3TAHyL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/71O9nzHQGmL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/71+3pPrJSQL._SL1500_.jpg",
      "https://m.media-amazon.com/images/I/61GyTtevBsL._SL1500_.jpg",
    ],
    name: 'Apple Newly Launched Wave Style Call with Advanced Dedicated Bluetooth Calling Chip, 600+ Watch Faces, 1.69" HD Display, Health Ecosystem',
    price: "1799",
    description: `BT Calling- Connect seamlessly, effortlessly with wave style call’s advanced bluetooth calling which ensures faster, stronger and improved connection.
        Calling Features- Wave Style Call smart watch comes with a dial pad, built in mic and speaker and you can save upto 10 contacts with this watch
        600+ Watch Faces- Be spoilt for choice with multiple watch faces. Find the one that matches your vibe with over 600+ watch faces on the cloud .`,
    companies: ["amazon", "apple"],
  },
  {
    key: "5",
    imageList: [
      "https://m.media-amazon.com/images/I/61WUqJd4dfS._SL1000_.jpg",
      "https://m.media-amazon.com/images/I/61EXU8BuGZL._SL1100_.jpg",
      "https://m.media-amazon.com/images/I/61+H0rsBOKL._SL1000_.jpg",
      "https://m.media-amazon.com/images/I/51APHRysB7L._SL1000_.jpg",
      "https://m.media-amazon.com/images/I/61ZS7uOVlzL._SL1000_.jpg",
    ],
    name: `Echo Dot (3rd Gen) – New and improved smart speaker with Alexa (Black)`,
    price: "2500",
    description:
      "Echo Dot is our best selling smart speaker that can be operated by voice - even from a distance. Alexa can speak both English & Hindi, and new features are added automatically. Hands-free music control: Stream millions of songs in your favorite language from Amazon Prime Music, Spotify, JioSaavn, Gaana, or Apple Music",
    companies: ["oneplus", "sony", "amazon"],
  },
];

export const companyOptions = [
  {
    label: 'Apple',
    value: 'apple',
  },
  {
    label: 'Sony',
    value: 'sony',
  },
  {
    label: 'Samsung',
    value: 'samsung',
  },
  {
    label: 'Huawei',
    value: 'huawei',
  },

]

export const imageUrl=[
    {
        key: 1,
        url:"https://ant-cra.cremawork.com/assets/images/sidebar/thumb/1.png"
    },
    {
        key: 2,
        url:"https://ant-cra.cremawork.com/assets/images/sidebar/thumb/2.png"
    },
    {
        key: 3,
        url:"https://ant-cra.cremawork.com/assets/images/sidebar/thumb/3.png"
    },
    {
        key: 4,
        url:"https://ant-cra.cremawork.com/assets/images/sidebar/thumb/4.png"
    }
    ,
    {
        key: 5,
        url:"https://ant-cra.cremawork.com/assets/images/sidebar/thumb/5.png"
    },
    
    
]


export const ApiMockResponse = [
  {
    id: 1651319512266.7095,
    title: "Discover",
    cards: [
      {
        id: 1651319552926.0933,
        title: "Task1",
        labels: [{ color: "#cf61a1", text: "Urgent" }],
        date: "2022-05-05",
        tasks: [
          { id: 1651319625559.8025, completed: true, text: "Task1_subtask1" },
          { id: 1651319629650.8945, completed: true, text: "Task1_subtask2" },
          { id: 1651319633774.9905, completed: true, text: "Task1_subtask3" },
        ],
        desc: "Task1 Detail Description",
      },
      {
        id: 1651319568365.593,
        title: "Task2",
        labels: [{ color: "#1ebffa", text: "Frontend" }],
        date: "",
        tasks: [],
      },
    ],
  },
  {
    id: 1651319523126.113,
    title: "Doing",
    cards: [
      {
        id: 1651319672685.5078,
        title: "Task3",
        labels: [{ color: "#9975bd", text: "Database" }],
        date: "",
        tasks: [
          { id: 1651319728301.3855, completed: false, text: "restore db" },
        ],
      },
    ],
  },
  {
    id: 1651319530017.122,
    title: "In-Review",
    cards: [
      {
        id: 1651319677070.0732,
        title: "Task4",
        labels: [{ color: "#8da377", text: "figma" }],
        date: "2022-05-06",
        tasks: [],
      },
    ],
  },
  {
    id: 1651319535931.4182,
    title: "Completed",
    cards: [
      {
        id: 1651319680948.0479,
        title: "Task5",
        labels: [{ color: "#4fcc25", text: "Payment API" }],
        date: "2022-05-04",
        tasks: [
          { id: 1651319820180.9648, completed: false, text: "GraphQl" },
          { id: 1651319833779.3252, completed: true, text: "Restful API" },
        ],
      },
    ],
  },
];
export const colorsList = [
  "#a8193d",
  "#4fcc25",
  "#1ebffa",
  "#8da377",
  "#9975bd",
  "#cf61a1",
  "#240959",
];

export const PricingData = [
  {
    key: "1",
    planName: "Free",
    currencySymbol: "$",
    planPrice: "0",
    planDuration: "/month",
    buttonName: "Try Now",
    pricingList: [
      '1 course to share privately',
      'No selling option',
      'No Social Marketing',
      'Courses & Pages are not discoverable',
      'No team of helpers',
      'No personal and page Blogs'
    ],
    discription: "Designed to help your building initial community and educational content."
  },
  {
    key: "2",
    planName: "Basic",
    currencySymbol: "$",
    planPrice: "69",
    planDuration: "/month",
    buttonName: "Buy Now",
    pricingList: [
      '1 course to share privately',
      'No selling option',
      'No Social Marketing',
      'Courses & Pages are not discoverable',
      'No team of helpers',
      'No personal and page Blogs'
    ],
    discription: "Designed to help your building initial community and educational content."
  },
  {
    key: "3",
    planName: "Pro",
    currencySymbol: "$",
    planPrice: "89",
    planDuration: "/month",
    buttonName: "Buy Now",
    pricingList: [
      '5 courses',
      'Can sell courses and charge users',
      'Social Marketing with social media',
      'Courses & Pages are not discoverable',
      'No team of helpers',
      'Create your personal and page Blogs'
    ],
    discription: "Designed to help your building initial community and educational content."
  },
  {
    key: "4",
    planName: "Growth",
    currencySymbol: "$",
    planPrice: "49",
    planDuration: "/month",
    buttonName: "Contact Sales",
    pricingList: [
      'Unlimited courses',
      'Can sell courses and charge users',
      'Marketing with social media',
      'Courses & Pages are not discoverable',
      'No team of helpers',
      'Create your personal and page Blogs'
    ],
    discription: "Designed to help your building initial community and educational content."
  },
]
export const pricingdatacolumns = [
  {
    title: " ",
    dataIndex: 'basicfeatures',
    key: 'basicfeatures',
  },
  {
    title: 'Free',
    dataIndex: 'free',
    key: 'free',
  },
  {
    title: 'Basic',
    dataIndex: 'basic',
    key: 'basic',
  },
  {
    title: 'Pro',
    dataIndex: 'pro',
    key: 'pro',
  }
];
export const pricingdataSources = [
  {
    key: "",
    basicfeatures: " ",
    free: <><h1>$0</h1><span>per year<br /> Basic features for up to 10<br /> employees with everything you need.</span>
      <br />
      <br />
      <Button className="w-full text-bold mb-4 border-black" shape="round" style={{ borderColor: "rgb(10, 143, 220)" }}>Try Now</Button></>,
    basic: <><h1>$69</h1><span>per year <br />Advanced features and reporting,<br /> better workflows and automation.</span>
      <br />
      <br />
      <Button className="w-full text-bold mb-4 border-black" shape="round" style={{ borderColor: " rgb(17, 193, 91)" }}>Buy Now</Button></>,
    pro: <><h1>$349</h1><span>per year <br />Personalised service and <br />
      enterprise security for large teams.</span>
      <br />
      <br />
      <Button className="w-full text-bold mb-4 border-black" shape="round" style={{ borderColor: "rgb(255, 139, 38)" }}>Buy Now</Button></>,
  },
  {
    key: "1",
    basicfeatures: <>Basic Features <QuestionCircleOutlined /></>,
    free: <CheckOutlined style={{ color: "green" }} />,
    basic: <CheckOutlined style={{ color: "green" }} />,
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "2",
    basicfeatures: <>Users <QuestionCircleOutlined /></>,
    free: "10GB",
    basic: "20GB",
    pro: "Unlimited",
  },
  {
    key: "3",
    basicfeatures: <>Individual Support <QuestionCircleOutlined /></>,
    free: "20GB",
    basic: "40GB",
    pro: "Unlimited",
  },
  {
    key: "4",
    basicfeatures: <> Support <QuestionCircleOutlined /></>,
    free: <CheckOutlined style={{ color: "green" }} />,
    basic: <CheckOutlined style={{ color: "green" }} />,
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "5",
    basicfeatures: <>Automated workflows <QuestionCircleOutlined /></>,
    free: "-",
    basic: <CheckOutlined style={{ color: "green" }} />,
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "6",
    basicfeatures: <>200+ Integrations <QuestionCircleOutlined /></>,
    free: "-",
    basic: <CheckOutlined style={{ color: "green" }} />,
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "7",
    basicfeatures: "Reporting And Analytics",
    free: " ",
    basic: " ",
    pro: " ",
  },
  {
    key: "8",
    basicfeatures: <>Analytics <QuestionCircleOutlined /></>,
    free: <CheckOutlined style={{ color: "green" }} />,
    basic: <CheckOutlined style={{ color: "green" }} />,
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "9",
    basicfeatures: <>Export Reports <QuestionCircleOutlined /></>,
    free: <CheckOutlined style={{ color: "green" }} />,
    basic: <CheckOutlined style={{ color: "green" }} />,
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "10",
    basicfeatures: <>Scheduled Reports <QuestionCircleOutlined /></>,
    free: "-",
    basic: <CheckOutlined style={{ color: "green" }} />,
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "11",
    basicfeatures: <>Api Access <QuestionCircleOutlined /></>,
    free: "-",
    basic: <CheckOutlined style={{ color: "green" }} />,
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "12",
    basicfeatures: <>Advanced Reports <QuestionCircleOutlined /></>,
    free: "-",
    basic: <CheckOutlined style={{ color: "green" }} />,
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "13",
    basicfeatures: <>Saved Reports <QuestionCircleOutlined /></>,
    free: "-",
    basic: <CheckOutlined style={{ color: "green" }} />,
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "14",
    basicfeatures: <>Customer Properties <QuestionCircleOutlined /></>,
    free: "-",
    basic: "-",
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "15",
    basicfeatures: <>Custom Fields <QuestionCircleOutlined /></>,
    free: "-",
    basic: "-",
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "16",
    basicfeatures: "User Access",
    free: " ",
    basic: " ",
    pro: " ",
  },
  {
    key: "17",
    basicfeatures: <>SSO/SAML Authentication<QuestionCircleOutlined /></>,
    free: <CheckOutlined style={{ color: "green" }} />,
    basic: <CheckOutlined style={{ color: "green" }} />,
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "18",
    basicfeatures: <>Advanced Permissions <QuestionCircleOutlined /></>,
    free: "-",
    basic: <CheckOutlined style={{ color: "green" }} />,
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "19",
    basicfeatures: <>Audit Log <QuestionCircleOutlined /></>,
    free: "-",
    basic: "-",
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "20",
    basicfeatures: <>Data Historys <QuestionCircleOutlined /></>,
    free: "-",
    basic: "-",
    pro: <CheckOutlined style={{ color: "green" }} />,
  },
  {
    key: "21",
    basicfeatures: " ",
    free: <Button className="w-full text-bold mb-4 border-black" shape="round" style={{ borderColor: "rgb(10, 143, 220)" }}>Buy Now</Button>,
    basic: <Button className="w-full text-bold mb-4 border-black" shape="round" style={{ borderColor: " rgb(17, 193, 91)" }}>Buy Now</Button>,
    pro: <Button className="w-full text-bold mb-4 border-black" shape="round" style={{ borderColor: "rgb(255, 139, 38)" }}>Buy Now</Button>,
  },
];


// Icons

export const DirectionalIconsoutlined = [
  {
    id: 1,
    icon: <StepBackwardOutlined style={{ fontSize: "36px" }} />,
    name: "StepBackwardOutlined",
  },
  {
    id: 2,
    icon: <StepForwardOutlined style={{ fontSize: "36px" }} />,
    name: "StepForwardOutlined",
  },
  {
    id: 3,
    icon: <FastBackwardOutlined style={{ fontSize: "36px" }} />,
    name: "FastBackwardOutlined",
  },

  {
    id: 4,
    icon: <FastForwardOutlined style={{ fontSize: "36px" }} />,
    name: "FastForwardOutlined",
  },
  {
    id: 5,
    icon: <ShrinkOutlined style={{ fontSize: "36px" }} />,
    name: "ShrinkOutlined",
  },
  {
    id: 6,
    icon: <ArrowsAltOutlined style={{ fontSize: "36px" }} />,
    name: "ArrowsAltOutlined",
  },
  {
    id: 7,
    icon: <DownOutlined style={{ fontSize: "36px" }} />,
    name: "DownOutlined",
  },
  {
    id: 8,
    icon: <UpOutlined style={{ fontSize: "36px" }} />,
    name: "UpOutlined",
  },
  {
    id: 9,
    icon: <LeftOutlined style={{ fontSize: "36px" }} />,
    name: "LeftOutlined",
  },
  {
    id: 10,
    icon: <RightOutlined style={{ fontSize: "36px" }} />,
    name: "RightOutlined",
  },
  {
    id: 11,
    icon: <CaretUpOutlined style={{ fontSize: "36px" }} />,
    name: "CaretUpOutlined",
  },
  {
    id: 12,
    icon: <CaretLeftOutlined style={{ fontSize: "36px" }} />,
    name: "CaretLeftOutlined",
  },
  {
    id: 13,
    icon: <CaretRightOutlined style={{ fontSize: "36px" }} />,
    name: "CaretRightOutlined",
  },
  {
    id: 14,
    icon: <UpCircleOutlined style={{ fontSize: "36px" }} />,
    name: "UpCircleOutlined",
  },
  {
    id: 15,
    icon: <DownCircleOutlined style={{ fontSize: "36px" }} />,
    name: "DownCircleOutlined",
  },
  {
    id: 16,
    icon: <LeftCircleOutlined style={{ fontSize: "36px" }} />,
    name: "LeftCircleOutlined",
  },
  {
    id: 17,
    icon: <RightCircleOutlined style={{ fontSize: "36px" }} />,
    name: "RightCircleOutlined",
  },
  {
    id: 18,
    icon: <DoubleRightOutlined style={{ fontSize: "36px" }} />,
    name: "DoubleRightOutlined",
  },

  {
    id: 19,
    icon: <DoubleLeftOutlined style={{ fontSize: "36px" }} />,
    name: "DoubleLeftOutlined",
  },
  {
    id: 20,
    icon: <VerticalLeftOutlined style={{ fontSize: "36px" }} />,
    name: "VerticalLeftOutlined",
  },
  {
    id: 21,
    icon: <VerticalRightOutlined style={{ fontSize: "36px" }} />,
    name: "VerticalRightOutlined",
  },
  {
    id: 22,
    icon: <VerticalAlignTopOutlined style={{ fontSize: "36px" }} />,
    name: "VerticalAlignTopOutlined",
  },
  {
    id: 23,
    icon: <VerticalAlignMiddleOutlined style={{ fontSize: "36px" }} />,
    name: "VerticalAlignMiddleOutlined",
  },
  {
    id: 18,
    icon: <VerticalAlignBottomOutlined style={{ fontSize: "36px" }} />,
    name: "VerticalAlignBottomOutlined",
  },
  {
    id: 18,
    icon: <ForwardOutlined style={{ fontSize: "36px" }} />,
    name: "ForwardOutlined",
  },
  {
    id: 18,
    icon: <BackwardOutlined style={{ fontSize: "36px" }} />,
    name: "BackwardOutlined",
  },
  {
    id: 18,
    icon: <RollbackOutlined style={{ fontSize: "36px" }} />,
    name: "RollbackOutlined",
  },
  {
    id: 18,
    icon: <EnterOutlined style={{ fontSize: "36px" }} />,
    name: "EnterOutlined",
  },

  {
    id: 18,
    icon: <RetweetOutlined style={{ fontSize: "36px" }} />,
    name: "RetweetOutlined",
  },
  {
    id: 18,
    icon: <SwapOutlined style={{ fontSize: "36px" }} />,
    name: "SwapOutlined",
  },
  {
    id: 18,
    icon: <SwapLeftOutlined style={{ fontSize: "36px" }} />,
    name: "SwapLeftOutlined",
  },
  {
    id: 18,
    icon: <SwapRightOutlined style={{ fontSize: "36px" }} />,
    name: "SwapRightOutlined",
  },
  {
    id: 18,
    icon: <ArrowUpOutlined style={{ fontSize: "36px" }} />,
    name: "ArrowUpOutlined",
  },
  {
    id: 18,
    icon: <ArrowDownOutlined style={{ fontSize: "36px" }} />,
    name: "ArrowDownOutlined",
  },
  {
    id: 18,
    icon: <ArrowLeftOutlined style={{ fontSize: "36px" }} />,
    name: "RollbackOutlined",
  },

  {
    id: 18,
    icon: <ArrowRightOutlined style={{ fontSize: "36px" }} />,
    name: "RollbackOutlined",
  },
  {
    id: 18,
    icon: <PlayCircleOutlined style={{ fontSize: "36px" }} />,
    name: "PlayCircleOutlined",
  },
  {
    id: 18,
    icon: <UpSquareOutlined style={{ fontSize: "36px" }} />,
    name: "UpSquareOutlined",
  },
  {
    id: 18,
    icon: <DownSquareOutlined style={{ fontSize: "36px" }} />,
    name: "DownSquareOutlined",
  },
  {
    id: 18,
    icon: <LeftSquareOutlined style={{ fontSize: "36px" }} />,
    name: "LeftSquareOutlined",
  },
  {
    id: 18,
    icon: <RightSquareOutlined style={{ fontSize: "36px" }} />,
    name: "RightSquareOutlined",
  },
  {
    id: 18,
    icon: <LoginOutlined style={{ fontSize: "36px" }} />,
    name: "LoginOutlined",
  },
  {
    id: 18,
    icon: <LogoutOutlined style={{ fontSize: "36px" }} />,
    name: "LogoutOutlined",
  },
  {
    id: 18,
    icon: <MenuFoldOutlined style={{ fontSize: "36px" }} />,
    name: "MenuFoldOutlined",
  },
  {
    id: 18,
    icon: <MenuUnfoldOutlined style={{ fontSize: "36px" }} />,
    name: "MenuUnfoldOutlined",
  },
  {
    id: 18,
    icon: <BorderBottomOutlined style={{ fontSize: "36px" }} />,
    name: "BorderBottomOutlined",
  },
  {
    id: 18,
    icon: <BorderHorizontalOutlined style={{ fontSize: "36px" }} />,
    name: "BorderHorizontalOutlined",
  },
  {
    id: 18,
    icon: <BorderInnerOutlined style={{ fontSize: "36px" }} />,
    name: "BorderInnerOutlined",
  },
  {
    id: 18,
    icon: <BorderOuterOutlined style={{ fontSize: "36px" }} />,
    name: "BorderOuterOutlined",
  },
  {
    id: 18,
    icon: <BorderLeftOutlined style={{ fontSize: "36px" }} />,
    name: "BorderLeftOutlined",
  },
  {
    id: 18,
    icon: <BorderRightOutlined style={{ fontSize: "36px" }} />,
    name: "BorderRightOutlined",
  },
  {
    id: 18,
    icon: <BorderTopOutlined style={{ fontSize: "36px" }} />,
    name: "BorderTopOutlined",
  },
  {
    id: 18,
    icon: <BorderVerticleOutlined style={{ fontSize: "36px" }} />,
    name: "BorderVerticleOutlined",
  },
  {
    id: 18,
    icon: <PicCenterOutlined style={{ fontSize: "36px" }} />,
    name: "PicCenterOutlined",
  },
  {
    id: 18,
    icon: <PicLeftOutlined style={{ fontSize: "36px" }} />,
    name: "PicLeftOutlined",
  },
  {
    id: 18,
    icon: <PicRightOutlined style={{ fontSize: "36px" }} />,
    name: "PicRightOutlined",
  },
  {
    id: 18,
    icon: <RadiusBottomleftOutlined style={{ fontSize: "36px" }} />,
    name: "RadiusBottomleftOutlined",
  },
  {
    id: 18,
    icon: <RadiusBottomrightOutlined style={{ fontSize: "36px" }} />,
    name: "RadiusBottomrightOutlined",
  },
  {
    id: 18,
    icon: <RadiusUpleftOutlined style={{ fontSize: "36px" }} />,
    name: "LeftSquareOutlined",
  },
  {
    id: 18,
    icon: <RadiusUprightOutlined style={{ fontSize: "36px" }} />,
    name: "LeftSquareOutlined",
  },
  {
    id: 18,
    icon: <FullscreenOutlined style={{ fontSize: "36px" }} />,
    name: "FullscreenOutlined",
  },
  {
    id: 18,
    icon: <FullscreenExitOutlined style={{ fontSize: "36px" }} />,
    name: "FullscreenExitOutlined",
  },
];

export const SuggestedIconoutlined = [
  {
    icon: <QuestionOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <QuestionCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PlusOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PlusCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PauseOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PauseCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MinusOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MinusCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PlusSquareOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MinusSquareOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PlusSquareOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MinusSquareOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <InfoOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <InfoCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ExclamationOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ExclamationCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CloseOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CloseCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CloseSquareOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CheckOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CheckCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ClockCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <WarningOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <IssuesCloseOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <StopOutlined style={{ fontSize: "36px" }} />,
  },
];

export const EditorIconoutlined = [
  {
    icon: <EditOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FormOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CopyOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ScissorOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DeleteOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SnippetsOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DiffOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <HighlightOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AlignCenterOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AlignLeftOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AlignRightOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BgColorsOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BoldOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ItalicOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UnderlineOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <StrikethroughOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RedoOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UndoOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ZoomInOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ZoomOutOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FontColorsOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FontSizeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <LineHeightOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DashOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SmallDashOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SortAscendingOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SortDescendingOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DragOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <OrderedListOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UnorderedListOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RadiusSettingOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ColumnWidthOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ColumnHeightOutlined style={{ fontSize: "36px" }} />,
  },
];

export const Dataiconsoutlined = [
  {
    icon: <AreaChartOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PieChartOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BarChartOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DotChartOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <LineChartOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RadarChartOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <HeatMapOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FallOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RiseOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <StockOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BoxPlotOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FundOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SlidersOutlined style={{ fontSize: "36px" }} />,
  },
];

export const Brandsiconoutlined = [
  {
    icon: <AndroidOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AppleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <WindowsOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <IeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ChromeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GithubOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AliwangwangOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DingdingOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <WeiboSquareOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <WeiboCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TaobaoCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <Html5Outlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <WeiboOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TwitterOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <WechatOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <WhatsAppOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <YoutubeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AlipayCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TaobaoOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DingtalkOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SkypeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <QqOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MediumWorkmarkOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GitlabOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MediumOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <LinkedinOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GooglePlusOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DropboxOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FacebookOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CodepenOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CodeSandboxOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AmazonOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GoogleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CodepenCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AlipayOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AntDesignOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AntCloudOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AliyunOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ZhihuOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SlackOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SlackSquareOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BehanceOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BehanceSquareOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DribbbleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DribbbleSquareOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <InstagramOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <YuqueOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AlibabaOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <YahooOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RedditOutlined style={{ fontSize: "36px" }} />,
  },
];

export const Appiconsoutlined = [
  {
    icon: <AccountBookOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AimOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AlertOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ApartmentOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ApiOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AppstoreAddOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AudioOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AudioMutedOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AuditOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BankOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BarcodeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BarsOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BellOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BlockOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BookOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BorderOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BorderlessTableOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BranchesOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BugOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BuildOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BulbOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CalculatorOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CalendarOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CameraOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CarOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CarryOutOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CiCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CiOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ClearOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CloudDownloadOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CloudOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CloudServerOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CloudSyncOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CloudUploadOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ClusterOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CodeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CoffeeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CommentOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CompassOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CompressOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ConsoleSqlOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ContactsOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ContainerOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ControlOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CopyrightOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CreditCardOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CrownOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CustomerServiceOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DashboardOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DatabaseOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DeleteColumnOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DeleteRowOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DeliveredProcedureOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DeploymentUnitOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DesktopOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DisconnectOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DislikeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DollarOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DownloadOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <EllipsisOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <EnvironmentOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <EuroCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <EuroOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ExceptionOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ExpandAltOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ExpandOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ExperimentOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ExportOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <EyeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <EyeInvisibleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FieldBinaryOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FieldNumberOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FieldStringOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FieldTimeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileAddOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileDoneOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileExcelOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileExclamationOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileGifOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileImageOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileJpgOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileMarkdownOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FilePdfOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FilePptOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileProtectOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileSearchOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileSyncOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileTextOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileUnknownOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileWordOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileZipOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FilterOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FireOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FlagOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FolderAddOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FolderOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FolderOpenOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FolderViewOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ForkOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FormatPainterOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FrownOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FunctionOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FundProjectionScreenOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FieldBinaryOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FundViewOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FunnelPlotOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GatewayOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GifOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GiftOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GlobalOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GoldOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GroupOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <HddOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <HeartOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <HistoryOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <HolderOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <HomeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <HourglassOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <IdcardOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ImportOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <InboxOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <InsertRowAboveOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <InsertRowBelowOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <InsertRowLeftOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <InsertRowRightOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <InsuranceOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <InteractionOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <KeyOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <LaptopOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <LayoutOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <LikeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <LineOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <LinkOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <Loading3QuartersOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <LockOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MacCommandOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MailOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ManOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MedicineBoxOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MehOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MenuOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MergeCellsOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MessageOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MobileOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MoneyCollectOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MonitorOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MoreOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <NodeCollapseOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <NodeExpandOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <NodeIndexOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <NotificationOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <NumberOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <OneToOneOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PaperClipOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PartitionOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PayCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PercentageOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PhoneOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PictureOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PlaySquareOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PoundCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PoundOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PoweroffOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PrinterOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ProfileOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ProjectOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PropertySafetyOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PullRequestOutlined style={{ fontSize: "36px" }} />,
  },

  {
    icon: <PushpinOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <QrcodeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ReadOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ReconciliationOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RedEnvelopeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ReloadOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RestOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RobotOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RocketOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RotateLeftOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RotateRightOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SafetyCertificateOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SafetyOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SaveOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ScanOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SendOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SettingOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ShakeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ShareAltOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ShopOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ShoppingCartOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ShoppingOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SisternodeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SkinOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SmileOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SolutionOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SoundOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SplitCellsOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <StarOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SubnodeOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SmileOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SwitcherOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SyncOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TableOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TabletOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TagOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TagsOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TeamOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ThunderboltOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ToTopOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ToolOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TrademarkCircleOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TrademarkOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TransactionOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TranslationOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TrophyOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UngroupOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UnlockOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UploadOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UsbOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UserAddOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UserDeleteOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UserOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UserSwitchOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UsergroupAddOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UsergroupDeleteOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <VerifiedOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <VideoCameraAddOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <VideoCameraOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <WalletOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <WifiOutlined style={{ fontSize: "36px" }} />,
  },
  {
    icon: <WomanOutlined style={{ fontSize: "36px" }} />,
  },
];

export const Directionalfilled = [
  {
    id: 1,
    icon: <StepBackwardFilled style={{ fontSize: "36px" }} />,
    name: "StepBackwardFilled",
  },
  {
    id: 2,
    icon: <StepForwardFilled style={{ fontSize: "36px" }} />,
    name: "StepForwardOutlined",
  },
  {
    id: 3,
    icon: <FastBackwardFilled style={{ fontSize: "36px" }} />,
    name: "FastBackwardOutlined",
  },

  {
    id: 4,
    icon: <FastForwardFilled style={{ fontSize: "36px" }} />,
    name: "FastForwardOutlined",
  },
  {
    id: 5,
    icon: <CaretUpFilled style={{ fontSize: "36px" }} />,
    name: "CaretUpFilled",
  },
  {
    id: 6,
    icon: <CaretDownFilled style={{ fontSize: "36px" }} />,
    name: "CaretDownFilled",
  },
  {
    id: 7,
    icon: <CaretLeftFilled style={{ fontSize: "36px" }} />,
    name: "DownOutlined",
  },
  {
    id: 8,
    icon: <CaretRightFilled style={{ fontSize: "36px" }} />,
    name: "CaretRightFilled",
  },
  {
    id: 9,
    icon: <UpCircleFilled style={{ fontSize: "36px" }} />,
    name: "LeftOutlined",
  },
  {
    id: 10,
    icon: <DownCircleFilled style={{ fontSize: "36px" }} />,
    name: "RightOutlined",
  },
  {
    id: 11,
    icon: <LeftCircleFilled style={{ fontSize: "36px" }} />,
    name: "LeftCircleFilled",
  },
  {
    id: 12,
    icon: <RightCircleFilled style={{ fontSize: "36px" }} />,
    name: "RightCircleFilled",
  },
  {
    id: 13,
    icon: <ForwardFilled style={{ fontSize: "36px" }} />,
    name: "ForwardFilled",
  },
  {
    id: 14,
    icon: <BackwardFilled style={{ fontSize: "36px" }} />,
    name: "BackwardFilled",
  },
  {
    id: 15,
    icon: <PlayCircleFilled style={{ fontSize: "36px" }} />,
    name: "PlayCircleFilled",
  },
  {
    id: 16,
    icon: <UpSquareFilled style={{ fontSize: "36px" }} />,
    name: "UpSquareFilled",
  },
  {
    id: 17,
    icon: <DownSquareFilled style={{ fontSize: "36px" }} />,
    name: "DownSquareFilled",
  },
  {
    id: 18,
    icon: <LeftSquareFilled style={{ fontSize: "36px" }} />,
    name: "LeftSquareFilled",
  },

  {
    id: 19,
    icon: <RightSquareFilled style={{ fontSize: "36px" }} />,
    name: "RightSquareFilled",
  },
];
export const SuggestedIconoFilled = [
  {
    id: 1,
    icon: <QuestionCircleFilled style={{ fontSize: "36px" }} />,
    name: "QuestionCircleFilled",
  },
  {
    id: 2,
    icon: <PlusCircleFilled style={{ fontSize: "36px" }} />,
    name: "PlusCircleFilled",
  },
  {
    id: 3,
    icon: <PauseCircleFilled style={{ fontSize: "36px" }} />,
    name: "PauseCircleFilled",
  },

  {
    id: 4,
    icon: <MinusCircleFilled style={{ fontSize: "36px" }} />,
    name: "MinusCircleFilled",
  },
  {
    id: 5,
    icon: <PlusSquareFilled style={{ fontSize: "36px" }} />,
    name: "PlusSquareFilled",
  },
  {
    id: 6,
    icon: <MinusSquareFilled style={{ fontSize: "36px" }} />,
    name: "MinusSquareFilled",
  },
  {
    id: 7,
    icon: <InfoCircleFilled style={{ fontSize: "36px" }} />,
    name: "InfoCircleFilled",
  },
  {
    id: 8,
    icon: <ExclamationCircleFilled style={{ fontSize: "36px" }} />,
    name: "ExclamationCircleFilled",
  },
  {
    id: 9,
    icon: <CloseCircleFilled style={{ fontSize: "36px" }} />,
    name: "CloseCircleFilled",
  },
  {
    id: 10,
    icon: <CloseSquareFilled style={{ fontSize: "36px" }} />,
    name: "CloseSquareFilled",
  },
  {
    id: 11,
    icon: <CheckCircleFilled style={{ fontSize: "36px" }} />,
    name: "CheckCircleFilled",
  },
  {
    id: 12,
    icon: <CheckSquareFilled style={{ fontSize: "36px" }} />,
    name: "CheckSquareFilled",
  },
  {
    id: 13,
    icon: <ClockCircleFilled style={{ fontSize: "36px" }} />,
    name: "ForwardFilled",
  },
  {
    id: 14,
    icon: <WarningFilled style={{ fontSize: "36px" }} />,
    name: "WarningFilled",
  },
  {
    id: 15,
    icon: <StopFilled style={{ fontSize: "36px" }} />,
    name: "StopFilled",
  },
];
export const EditorIconfilled = [
  {
    id: 1,
    icon: <EditFilled style={{ fontSize: "36px" }} />,
    name: "EditFilled",
  },
  {
    id: 2,
    icon: <CopyFilled style={{ fontSize: "36px" }} />,
    name: "CopyFilled",
  },
  {
    id: 3,
    icon: <DeleteFilled style={{ fontSize: "36px" }} />,
    name: "DeleteFilled",
  },

  {
    id: 4,
    icon: <SnippetsFilled style={{ fontSize: "36px" }} />,
    name: "SnippetsFilled",
  },
  {
    id: 5,
    icon: <DiffFilled style={{ fontSize: "36px" }} />,
    name: "DiffFilled",
  },
  {
    id: 6,
    icon: <HighlightFilled style={{ fontSize: "36px" }} />,
    name: "HighlightFilled",
  },
];
export const DataIconfilled = [
  {
    id: 1,
    icon: <PieChartFilled style={{ fontSize: "36px" }} />,
    name: "PieChartFilled",
  },
  {
    id: 2,
    icon: <BoxPlotFilled style={{ fontSize: "36px" }} />,
    name: "BoxPlotFilled",
  },
  {
    id: 3,
    icon: <FundFilled style={{ fontSize: "36px" }} />,
    name: "FundFilled",
  },

  {
    id: 4,
    icon: <SlidersFilled style={{ fontSize: "36px" }} />,
    name: "SlidersFilled",
  },
];

export const Brandsiconfilled = [
  {
    id: 1,
    icon: <AndroidFilled style={{ fontSize: "36px" }} />,
    name: "AndroidFilled",
  },
  {
    id: 2,
    icon: <AppleFilled style={{ fontSize: "36px" }} />,
    name: "AppleFilled",
  },
  {
    id: 3,
    icon: <WindowsFilled style={{ fontSize: "36px" }} />,
    name: "WindowsFilled",
  },

  {
    id: 4,
    icon: <ChromeFilled style={{ fontSize: "36px" }} />,
    name: "ChromeFilled",
  },
  {
    id: 1,
    icon: <GithubFilled style={{ fontSize: "36px" }} />,
    name: "GithubFilled",
  },
  {
    id: 2,
    icon: <AliwangwangFilled style={{ fontSize: "36px" }} />,
    name: "AliwangwangFilled",
  },
  {
    id: 3,
    icon: <WeiboSquareFilled style={{ fontSize: "36px" }} />,
    name: "WeiboSquareFilled",
  },

  {
    id: 4,
    icon: <WeiboCircleFilled style={{ fontSize: "36px" }} />,
    name: "WeiboCircleFilled",
  },
  {
    id: 1,
    icon: <TaobaoCircleFilled style={{ fontSize: "36px" }} />,
    name: "TaobaoCircleFilled",
  },
  {
    id: 2,
    icon: <Html5Filled style={{ fontSize: "36px" }} />,
    name: "Html5Filled",
  },
  {
    id: 3,
    icon: <WechatFilled style={{ fontSize: "36px" }} />,
    name: "WechatFilled",
  },

  {
    id: 4,
    icon: <YoutubeFilled style={{ fontSize: "36px" }} />,
    name: "YoutubeFilled",
  },
  {
    id: 1,
    icon: <AlipayCircleFilled style={{ fontSize: "36px" }} />,
    name: "AlipayCircleFilled",
  },
  {
    id: 2,
    icon: <SkypeFilled style={{ fontSize: "36px" }} />,
    name: "SkypeFilled",
  },
  {
    id: 3,
    icon: <GitlabFilled style={{ fontSize: "36px" }} />,
    name: "GitlabFilled",
  },

  {
    id: 4,
    icon: <LinkedinFilled style={{ fontSize: "36px" }} />,
    name: "LinkedinFilled",
  },
  {
    id: 1,
    icon: <FacebookFilled style={{ fontSize: "36px" }} />,
    name: "FacebookFilled",
  },
  {
    id: 2,
    icon: <CodeSandboxCircleFilled style={{ fontSize: "36px" }} />,
    name: "CodeSandboxCircleFilled",
  },
  {
    id: 3,
    icon: <CodepenCircleFilled style={{ fontSize: "36px" }} />,
    name: "CodepenCircleFilled",
  },

  {
    id: 4,
    icon: <SlackSquareFilled style={{ fontSize: "36px" }} />,
    name: "SlackSquareFilled",
  },
  {
    id: 1,
    icon: <BehanceSquareFilled style={{ fontSize: "36px" }} />,
    name: "BehanceSquareFilled",
  },
  {
    id: 2,
    icon: <DribbbleSquareFilled style={{ fontSize: "36px" }} />,
    name: "DribbbleSquareFilled",
  },
  {
    id: 3,
    icon: <InstagramFilled style={{ fontSize: "36px" }} />,
    name: "InstagramFilled",
  },

  {
    id: 4,
    icon: <YuqueFilled style={{ fontSize: "36px" }} />,
    name: "YuqueFilled",
  },
  {
    id: 4,
    icon: <YahooFilled style={{ fontSize: "36px" }} />,
    name: "YahooFilled",
  },
];

export const Appiconsfilled = [
  {
    id: 1,
    icon: <AccountBookFilled style={{ fontSize: "36px" }} />,
    name: "AccountBookFilled",
  },
  {
    id: 2,
    icon: <AlertFilled style={{ fontSize: "36px" }} />,
    name: "AlertFilled",
  },
  {
    id: 3,
    icon: <AlipaySquareFilled style={{ fontSize: "36px" }} />,
    name: "AlipaySquareFilled",
  },

  {
    id: 4,
    icon: <AmazonCircleFilled style={{ fontSize: "36px" }} />,
    name: "AmazonCircleFilled",
  },
  {
    id: 5,
    icon: <AmazonSquareFilled style={{ fontSize: "36px" }} />,
    name: "AmazonSquareFilled",
  },
  {
    id: 6,
    icon: <ApiFilled style={{ fontSize: "36px" }} />,
    name: "ApiFilled",
  },
  {
    id: 7,
    icon: <AppstoreFilled style={{ fontSize: "36px" }} />,
    name: "AppstoreFilled",
  },

  {
    id: 8,
    icon: <AudioFilled style={{ fontSize: "36px" }} />,
    name: "AudioFilled",
  },
  {
    id: 9,
    icon: <BankFilled style={{ fontSize: "36px" }} />,
    name: "BankFilled",
  },
  {
    id: 10,
    icon: <BehanceCircleFilled style={{ fontSize: "36px" }} />,
    name: "BehanceCircleFilled",
  },
  {
    id: 11,
    icon: <BellFilled style={{ fontSize: "36px" }} />,
    name: "BellFilled",
  },

  {
    id: 12,
    icon: <BookFilled style={{ fontSize: "36px" }} />,
    name: "BookFilled",
  },
  {
    id: 13,
    icon: <BugFilled style={{ fontSize: "36px" }} />,
    name: "BugFilled",
  },
  {
    id: 14,
    icon: <BuildFilled style={{ fontSize: "36px" }} />,
    name: "BuildFilled",
  },
  {
    id: 15,
    icon: <BulbFilled style={{ fontSize: "36px" }} />,
    name: "BulbFilled",
  },

  {
    id: 4,
    icon: <CalculatorFilled style={{ fontSize: "36px" }} />,
  },
  {
    id: 1,
    icon: <CalendarFilled style={{ fontSize: "36px" }} />,
  },
  {
    id: 2,
    icon: <CameraFilled style={{ fontSize: "36px" }} />,
  },
  {
    id: 3,
    icon: <CarFilled style={{ fontSize: "36px" }} />,
  },

  {
    id: 4,
    icon: <CarryOutFilled style={{ fontSize: "36px" }} />,
  },
  {
    id: 1,
    icon: <CiCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    id: 2,
    icon: <CloudFilled style={{ fontSize: "36px" }} />,
  },
  {
    id: 3,
    icon: <CodeSandboxSquareFilled style={{ fontSize: "36px" }} />,
  },

  {
    id: 4,
    icon: <CodepenSquareFilled style={{ fontSize: "36px" }} />,
  },
  {
    id: 4,
    icon: <CompassFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ContactsFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ContainerFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ControlFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CreditCardFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CrownFilled style={{ fontSize: "36px" }} />,
  },

  {
    icon: <CustomerServiceFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DashboardFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DatabaseFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DingtalkCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DingtalkSquareFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DislikeFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DribbbleCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DropboxCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DropboxSquareFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <EnvironmentFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ExperimentFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <EyeFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <EyeInvisibleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileAddFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileExcelFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileExclamationFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileImageFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileMarkdownFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FilePdfFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FilePptFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileTextFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileUnknownFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileWordFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileZipFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FilterFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FireFilled style={{ fontSize: "36px" }} />,
  },

  {
    icon: <FlagFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FolderAddFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FolderOpenFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FormatPainterFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FrownFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FunnelPlotFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GiftFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GoldFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GoldenFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GoogleCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GooglePlusCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GoogleSquareFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <HddFilled style={{ fontSize: "36px" }} />,
  },

  {
    icon: <HeartFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <HomeFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <HourglassFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <IdcardFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <IeCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <IeSquareFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <InsuranceFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <InteractionFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <LayoutFilled style={{ fontSize: "36px" }} />,
  },

  {
    icon: <LikeFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <LockFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MacCommandFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MailFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MedicineBoxFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MediumCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MediumSquareFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MehFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MessageFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MobileFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MoneyCollectFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <NotificationFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PayCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PhoneFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PictureFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PlaySquareFilled style={{ fontSize: "36px" }} />,
  },

  {
    icon: <PoundCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PrinterFilled style={{ fontSize: "36px" }} />,
  },

  {
    icon: <ProfileFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ProjectFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PropertySafetyFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PushpinFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <QqCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <QqSquareFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ReadFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ReconciliationFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RedEnvelopeFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RedditCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RedditSquareFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RestFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RobotFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RocketFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SafetyCertificateFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SaveFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ScheduleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SecurityScanFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SettingFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ShopFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ShoppingFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SignalFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SketchCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SketchSquareFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SkinFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SlackCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SmileFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SoundFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <StarFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SwitcherFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TabletFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TagFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TagsFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TaobaoSquareFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ThunderboltFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ToolFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TrademarkCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TrophyFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TwitterCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TwitterSquareFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UnlockFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UsbFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <VideoCameraFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <WalletFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ZhihuCircleFilled style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ZhihuSquareFilled style={{ fontSize: "36px" }} />,
  },
];

export const DirectionalTwotone = [
  {
    id: 1,
    icon: <UpCircleTwoTone style={{ fontSize: "36px" }} />,
  },

  {
    id: 2,
    icon: <DownCircleTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 3,
    icon: <LeftCircleTwoTone style={{ fontSize: "36px" }} />,
  },

  {
    id: 4,
    icon: <RightCircleTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 1,
    icon: <PlayCircleTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 2,
    icon: <UpSquareTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 3,
    icon: <DownSquareTwoTone style={{ fontSize: "36px" }} />,
  },

  {
    id: 4,
    icon: <LeftSquareTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 4,
    icon: <RightSquareTwoTone style={{ fontSize: "36px" }} />,
  },
];
export const SuggestedIconTwotone = [
  {
    id: 1,
    icon: <QuestionCircleTwoTone style={{ fontSize: "36px" }} />,
  },

  {
    id: 2,
    icon: <PlusCircleTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 3,
    icon: <PauseCircleTwoTone style={{ fontSize: "36px" }} />,
  },

  {
    id: 4,
    icon: <MinusCircleTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 5,
    icon: <PlusSquareTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 6,
    icon: <MinusSquareTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 7,
    icon: <InfoCircleTwoTone style={{ fontSize: "36px" }} />,
  },

  {
    id: 8,
    icon: <ExclamationCircleTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 9,
    icon: <CloseCircleTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 10,
    icon: <CheckCircleTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 11,
    icon: <CheckSquareTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 12,
    icon: <ClockCircleTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 13,
    icon: <WarningTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 14,
    icon: <StopTwoTone style={{ fontSize: "36px" }} />,
  },
];

export const EditorIconTwotone = [
  {
    id: 1,
    icon: <EditTwoTone style={{ fontSize: "36px" }} />,
  },

  {
    id: 2,
    icon: <CopyTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 3,
    icon: <DeleteTwoTone style={{ fontSize: "36px" }} />,
  },

  {
    id: 4,
    icon: <SnippetsTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 5,
    icon: <DiffTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 6,
    icon: <HighlightTwoTone style={{ fontSize: "36px" }} />,
  },
];
export const DataIconTwotone = [
  {
    id: 1,
    icon: <PieChartTwoTone style={{ fontSize: "36px" }} />,
  },

  {
    id: 2,
    icon: <BoxPlotTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    id: 3,
    icon: <FundTwoTone style={{ fontSize: "36px" }} />,
  },

  {
    id: 4,
    icon: <SlidersTwoTone style={{ fontSize: "36px" }} />,
  },
];
export const BrandsiconTwotone = [
  {
    id: 1,
    icon: <Html5TwoTone style={{ fontSize: "36px" }} />,
  },
];

export const AppiconsTwotone = [
  {
    icon: <AccountBookTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AlertTwoTone style={{ fontSize: "36px" }} />,
  },

  {
    icon: <ApiTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AppstoreTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <AudioTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BankTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BellTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BookTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BugTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BuildTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <BulbTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CalculatorTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CalendarTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CameraTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CarTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CarryOutTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CiCircleTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CiTwoTone style={{ fontSize: "36px" }} />,
  },

  {
    icon: <CloudTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CodeTwoTone style={{ fontSize: "36px" }} />,
  },

  {
    icon: <CompassTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ContactsTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ContainerTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ControlTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CopyrightTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CreditCardTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CrownTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <CustomerServiceTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DashboardTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DatabaseTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DislikeTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <DollarTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <EnvironmentTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <EuroCircleTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <EuroTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ExperimentTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <EyeTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <EyeInvisibleTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileAddTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileExcelTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileExclamationTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileImageTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileMarkdownTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FilePdfTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FilePptTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileTextTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileUnknownTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileWordTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FileZipTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FilterTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FireTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FlagTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FolderAddTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FolderTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FolderOpenTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FrownTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <FunnelPlotTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GiftTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <GoldTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <HddTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <HeartTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <HomeTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <HourglassTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <IdcardTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <InsuranceTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <InteractionTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <LayoutTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <LikeTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <LockTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MailTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MedicineBoxTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MehTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MessageTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MobileTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <MoneyCollectTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <NotificationTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PhoneTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PictureTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PlaySquareTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PoundCircleTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PrinterTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ProfileTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ProjectTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PropertySafetyTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <PushpinTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ReconciliationTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RedEnvelopeTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RestTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <RocketTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SafetyCertificateTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SaveTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ScheduleTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SecurityScanTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SettingTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ShopTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ShoppingTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SkinTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SmileTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SoundTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <StarTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <SwitcherTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TabletTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TagTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TagsTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ThunderboltTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <ToolTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TrademarkCircleTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <TrophyTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UnlockTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <UsbTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <VideoCameraTwoTone style={{ fontSize: "36px" }} />,
  },
  {
    icon: <WalletTwoTone style={{ fontSize: "36px" }} />,
  },
];
export const testimonialData = [
  {
    name: "Jay Dave",
    avatar: "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5",
    post: "UI UX Designer",
    content: "It is a long established fact that a reader will page when looking at its was layout. The point of be distracted by the readable will page when looking at its was layout will page when looking.",
  },
  {
    name: "Jane Smith",
    avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvGHmq6yLYVHWfmoaMM2XzGkowpQCdiUvJpw1C7SjlihAH8hpmyFjnqEIQYUAmL6o5hBc&usqp=CAU",
    post: "Soft Developer",
    content: "It is a long established fact that a reader will page when looking at its was layout. The point of be distracted by the readable will page when looking at its was layout will page when looking.",
  },
  {
    name: "Jay Dave",
    avatar: "https://i.pravatar.cc/250?u=mail@ashallendesign.co.uk",
    post: "Tester",
    content: "It is a long established fact that a reader will page when looking at its was layout. The point of be distracted by the readable will page when looking at its was layout will page when looking.",
  },
  {
    name: "Jane Smith",
    avatar: "https://i.pravatar.cc/250?u=mail@ashallendesign.co.uk",
    post: "Soft Developer",
    content: "It is a long established fact that a reader will page when looking at its was layout. The point of be distracted by the readable will page when looking at its was layout will page when looking.",
  },
  {
    name: "Jay Dave",
    avatar: "https://placebeard.it/250/250",
    post: "UI UX Designer",
    content: "It is a long established fact that a reader will page when looking at its was layout. The point of be distracted by the readable will page when looking at its was layout will page when looking.",
  },
  {
    name: "Purvi Patel",
    avatar: "https://i.pravatar.cc/250?u=mail@ashallendesign.co.uk",
    post: "Tester",
    content: "It is a long established fact that a reader will page when looking at its was layout. The point of be distracted by the readable will page when looking at its was layout will page when looking.",
  },
];
