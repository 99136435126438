import { createSlice } from "@reduxjs/toolkit";
import {
  getAllRolesAction,
  getAllPermissionsAction,
  createRoleAction,
  editRoleAction,
  getPermissionsForRoleAction,
  allRolesForDropAction,
  deleteRoleAction,
} from "./actions";
import { notification } from "antd";

const initialState = {
  AllRoles: {},
  loadinggetAllRoles: false,
  errorgetAllRoles: null,

  allPermission: [],
  loadingAllPermissions: false,
  errorAllPermissions: null,

  createdRole: [],
  loadingCreatedRole: false,
  errorCreatedRole: null,

  editedRole: {},
  loadingEditedRole: false,
  errorEditedRole: null,

  roleData: null,
  loadingroleData: false,
  errorEditedRole: null,

  AllRolesForDropdown: {},
  loadinggetAllRolesForDropdown: false,
  errorgetAllRolesForDropdown: null,

  deleteRole: {},
  loadingDelete: false,
  errorDelete: null,
};
const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllRolesAction.pending, (state) => {
        state.loadinggetAllRoles = true;
        state.errorgetAllRoles = null;
      })
      .addCase(getAllRolesAction.fulfilled, (state, action) => {
        console.log("action", action.payload);
        state.loadinggetAllRoles = false;
        state.AllRoles = action.payload;
      })
      .addCase(getAllRolesAction.rejected, (state, action) => {
        state.loadinggetAllRoles = false;
        state.errorgetAllRoles = action.payload;
      })
      .addCase(createRoleAction.pending, (state) => {
        state.loadingCreatedRole = true;
        state.errorCreatedRole = null;
      })
      .addCase(createRoleAction.fulfilled, (state, action) => {
        state.loadingCreatedRole = false;
        state.createdRole = action.payload;
       
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(createRoleAction.rejected, (state, action) => {
        state.loadingCreatedRole = true;
        state.errorCreatedRole = action.payload;
      })
      .addCase(editRoleAction.pending, (state) => {
        state.loadingEditedRole = true;
        state.errorEditedRole = null;
      })
      .addCase(editRoleAction.fulfilled, (state, action) => {
        state.loadingEditedRole = false;
        state.editedRole = action.payload;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(editRoleAction.rejected, (state, action) => {
        state.loadingEditedRole = false;
        state.errorEditedRole = action.payload;
      })

      .addCase(getAllPermissionsAction.pending, (state) => {
        state.loadingAllPermissions = true;
        state.errorAllPermissions = null;
      })
      .addCase(getAllPermissionsAction.fulfilled, (state, action) => {
        state.loadingAllPermissions = false;
        state.allPermission = action.payload;
      })
      .addCase(getAllPermissionsAction.rejected, (state, action) => {
        state.loadingAllPermissions = false;
        state.errorAllPermissions = action.payload;
      })
      .addCase(getPermissionsForRoleAction.pending, (state) => {
        state.loadingroleData = true;
        state.errorEditedRole = null;
      })
      .addCase(getPermissionsForRoleAction.fulfilled, (state, action) => {
        state.loadingroleData = false;
        state.roleData = action.payload;
      })
      .addCase(getPermissionsForRoleAction.rejected, (state, action) => {
        state.loadingroleData = false;
        state.errorEditedRole = action.payload;
      })
      .addCase(allRolesForDropAction.pending, (state) => {
        state.loadinggetAllRolesForDropdown = true;
        state.errorgetAllRolesForDropdown = null;
      })
      .addCase(allRolesForDropAction.fulfilled, (state, action) => {
        state.loadinggetAllRolesForDropdown = false;
        state.AllRolesForDropdown = action.payload;
      })
      .addCase(allRolesForDropAction.rejected, (state, action) => {
        state.loadinggetAllRolesForDropdown = false;
        state.errorgetAllRolesForDropdown = action.payload;
      })
      .addCase(deleteRoleAction.pending, (state) => {
        state.loadingDelete = true;
        state.errorDelete = null;
      })
      .addCase(deleteRoleAction.fulfilled, (state, action) => {
        state.loadingDelete = false;
        state.deleteRole = action.payload;
        state.AllRoles.data = state.AllRoles.data.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        state.AllRoles.meta.pagination.totalItems -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteRoleAction.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.payload;
      });
  },
});
export default rolesSlice.reducer;
