import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUser,profileUpdate } from "../../api/user";

export const fetchUser = createAsyncThunk(
  "get/user",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getUser(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateUserAction = createAsyncThunk(
  "update/user",
  async (data, { rejectWithValue }) => {
    try {
      const response = await profileUpdate(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

