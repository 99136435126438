import { createSlice } from "@reduxjs/toolkit";
import {
  createChildAction,
  deleteChildAction,
  getChildAction,
  getAllSchoolAction,
  getChildDetailAction,
} from "./actions";
import { notification } from "antd";

const initialState = {
  createdChild: {},
  loadingCreate: false,
  errorCreate: null,

  editedChild: {},
  loadingEdit: false,
  errorEdit: null,

  getAllChildren: {},
  loadingAll: false,
  errorAll: null,

  deletedChild: {},
  loadingDelete: false,
  errorDelete: null,

  schoolList: {},
  loadingSchool: false,
  errorSchool: null,

  childDetail: {},
  loadingChildDetail: false,
  errorChildDetail: null,
};
const childSlice = createSlice({
  name: "child",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createChildAction.pending, (state) => {
        state.loadingCreate = true;
        state.errorCreate = null;
      })
      .addCase(createChildAction.fulfilled, (state, action) => {
        state.loadingCreate = false;
        state.createdChild = action.payload;
        if (action?.payload?.meta?.success === true) {
          state?.getAllChildren?.children?.push(action.payload?.data);
        }
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(createChildAction.rejected, (state, action) => {
        state.loadingCreate = false;
        state.errorCreate = action.payload;
      })
      .addCase(getChildAction.pending, (state) => {
        state.loadingAll = true;
        state.errorAll = null;
      })
      .addCase(getChildAction.fulfilled, (state, action) => {
        console.log("Takshak action", action.payload);
        state.loadingAll = false;
        state.getAllChildren = action.payload;
      })
      .addCase(getChildAction.rejected, (state, action) => {
        state.loadingAll = false;
        state.errorAll = action.payload;
      })
      .addCase(deleteChildAction.pending, (state) => {
        state.loadingDelete = true;
        state.errorDelete = null;
      })
      .addCase(deleteChildAction.fulfilled, (state, action) => {
        state.loadingDelete = false;
        state.deletedChild = action.payload;
        console.log("action.payload", action.payload);
        console.log("state", state);
        state.getAllChildren.data = state?.getAllChildren?.data?.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteChildAction.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.payload;
      })
      .addCase(getAllSchoolAction.pending, (state) => {
        state.loadingSchool = true;
        state.errorSchool = null;
      })
      .addCase(getAllSchoolAction.fulfilled, (state, action) => {
        state.loadingSchool = false;
        state.schoolList = action.payload;
      })
      .addCase(getAllSchoolAction.rejected, (state, action) => {
        state.loadingSchool = false;
        state.errorSchool = action.payload;
      })
      .addCase(getChildDetailAction.pending, (state) => {
        state.loadingChildDetail = true;
        state.errorChildDetail = null;
      })
      .addCase(getChildDetailAction.fulfilled, (state, action) => {
        state.loadingSchool = false;
        state.childDetail = action.payload;
      })
      .addCase(getChildDetailAction.rejected, (state, action) => {
        state.loadingChildDetail = false;
        state.errorChildDetail= action.payload;
      });
  },
});
export default childSlice.reducer;
