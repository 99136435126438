import { get, post, patch, del, put } from "../../helpers/apiWrapper";

// POST API for createSport
export const createSport = async (sportData) => {
  const config = {
    data: sportData,
  };
  console.log("Creating", config);
  return await post(`/sports/create`, config);
};

//Edit sports data
export const editSport = async ({ formData, data }) => {
  const config = {
    data: formData,
  };
  return await put(`/sports/${data.id}`, config);
};

// GET API for get all sports
export const getAllSports = async (data) => {
  const config = {
    params: data,
  };
  return await get(`/sports/getall`, config);
};

// delete Employee
export const deleteSport = async (data) => {
  return await del(`/sports/${data?.id}`);
};
