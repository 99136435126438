import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllRoles,getAllPermissions,createRole,updateRole,getPermissionsForRole,getAllRolesForDropdown,deleteRole } from "../../api/roles";

// Thunk action for get all user
export const getAllRolesAction = createAsyncThunk(
  "roles/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllRoles(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for edit role
export const editRoleAction = createAsyncThunk(
  "roles/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateRole(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for get permission for Specific role
export const getPermissionsForRoleAction = createAsyncThunk(
  "roles/getId",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getPermissionsForRole(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// // Thunk action for edit user
export const createRoleAction = createAsyncThunk(
  "role/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createRole(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllPermissionsAction = createAsyncThunk(
  "permissions/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllPermissions();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Thunk action for get all roles for dropdown
export const allRolesForDropAction = createAsyncThunk(
  "roles/getAllDrop",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllRolesForDropdown();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk action for delelte role
export const deleteRoleAction = createAsyncThunk(
  "role/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteRole(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
