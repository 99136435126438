import { createSlice } from "@reduxjs/toolkit";
import { fetchParent, createParentAction, deleteParentAction } from "./actions";
import { notification } from "antd";

const initialState = {
  createParent: {},
  loadingCreate: false,
  errorCreate: null,

  editedParent: {},
  loadingEdit: false,
  errorEdit: null,

  getAllParent: [],
  loadingGetAllParent: false,
  errorGetAllParent: null,

  deletedParent: {},
  loadingDelete: false,
  errorDelete: null,
};
const parentSlice = createSlice({
  name: "parent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchParent.pending, (state) => {
        state.loadingGetAllParent = true;
        state.errorGetAllParent = null;
      })
      .addCase(fetchParent.fulfilled, (state, action) => {
        state.loadingGetAllParent = false;
        state.getAllParent = action.payload.data;
      })
      .addCase(fetchParent.rejected, (state, action) => {
        state.loadingGetAllParent = false;
        state.errorGetAllParent = null;
      })
      .addCase(createParentAction.pending, (state) => {
        state.loadingCreate = true;
        state.errorCreate = null;
      })
      .addCase(createParentAction.fulfilled, (state, action) => {
        state.loadingCreate = false;
        state.createParent = action.payload;
        if (action?.payload?.meta?.success === true) {
          state?.getAllParent?.data?.push(action.payload?.data);
        }
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.message,
            duration: 2,
          });
        }
      })
      .addCase(createParentAction.rejected, (state, action) => {
        state.loadingCreate = false;
        state.errorCreate = action.payload;
      })
      .addCase(deleteParentAction.pending, (state) => {
        state.loadingDelete = true;
        state.errorDelete = null;
      })
      .addCase(deleteParentAction.fulfilled, (state, action) => {
        state.loadingDelete = false;
        state.deletedParent = action.payload;
        state.getAllParent.data = state?.getAllParent?.data?.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteParentAction.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.payload;
      });
  },
});

export default parentSlice.reducer;
