import { get, post, patch, del,put } from "../../helpers/apiWrapper";

//get Api for get roles
export const getAllRoles = async (data) => {
  const config = {
    params: data,
  };
  return await get(`/roles/getall`,config);
};
// POST API for create Role
export const createRole = async (userData) => {
  const config = {
    data: userData,
  };
  return await post(`/roles/create`, config);
};

// put api for update role
export const updateRole = async (data) => {
  const config = {
    data: data,
  };
  return await post(`/roles/update`, config);
};

// get all permission for specific role
export const getPermissionsForRole = async (data) => {
  return await get(`/roles/${data?.id}`);
};


//get all permissions

export const getAllPermissions = async (data) => {
  return await get(`/permissions/getall`);
};

//export const get all roles for dropdown

export const getAllRolesForDropdown = async (data) => {
  // const config = {
  //   params: data,
  // };
  return await get(`/roles/activeroles`);
};

// delete api  for roles 
export const deleteRole = async (data) => {
  return await del(`/roles/${data?.id}`);
};