import { configureStore } from "@reduxjs/toolkit";

import sidebarCollapseReducer from "../../reducers/SidebarCollapse";
import darkModeReducer from "../../reducers/DarkMode";
import RtlModeReducer from "../../reducers/RtlMode";
import authenticationReducer from "../store/Authentication/slice";
import employeeReducer from "../store/Employee/slice";
import getUserReducer from "../store/GetUser/slice";
import parentReducer from "../store/Parent&Child/slice";
import rolesReducer from "../store/Roles&Permissions/slice";
import sportsReducer from "../store/Sports/slice";
import childReducer from "../store/child/slice";

export default configureStore({
  reducer: {
    sidebarCollapse: sidebarCollapseReducer,
    darkMode: darkModeReducer,
    rtlMode: RtlModeReducer,
    authentication: authenticationReducer,
    employee: employeeReducer,
    getUser: getUserReducer,
    parent: parentReducer,
    roles: rolesReducer,
    sports: sportsReducer,
    child: childReducer,
  },
});
