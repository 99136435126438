import React from "react";
import "./SideBar.scss";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo from "../../../Assets/dummy-logo.png";
import logoOneByOne from "../../../Assets/logo11.png";

import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Menu, theme } from "antd";
import { toggleSidebar } from "../../../reducers/SidebarCollapse";
import { useNavigate } from "react-router-dom";
import { items } from "../../../constants";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideBar = ({ selectedImage, showImage }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const dispatch = useDispatch();

  const onClickLogo = () => {
    navigate("/");
  };

  const {
    token: { colorPrimary },
  } = theme.useToken();
  console.log(colorPrimary);

  const { isCollapse } = useSelector((state) => state.sidebarCollapse);
  const { userDetail } = useSelector((state) => state.getUser);

  const userData = JSON.parse(localStorage.getItem("data"));

  const permissions = userData?.role?.permissions;
  const slugs = permissions?.map((permission) => permission.slug);

  const addd = items.filter((item) => {
    return item?.access?.some((element) => slugs?.includes(element));
  });

  return (
    <>
      <Sider
        trigger={null}
        collapsible
        width={250}
        collapsed={isCollapse}
        className={` h-[100vh] main-side-bar `}
      >
        <div
          className={
            isCollapse ? `logoBoxContainerOneByOne` : `logoBoxContainer `
          }
          onClick={onClickLogo}
          data-testid="sidebar-top-logo"
        >
          <div className="logoBox">
            <img
              src={isCollapse ? logoOneByOne : logo}
              alt="logo"
              data-testid="image-logo-id"
            />
          </div>
        </div>
        <div className="sideBarMenuList pb-6" data-testid="main-sideBarMenu-id">
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[location.pathname]}
            // items={items}
            className="pb-4"
          >
            {addd?.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  {item?.isSubmenu === false ? (
                    <Menu.Item key={item?.key}>
                      <NavLink to={item?.route}>
                        {item?.icon}
                        <span>{item?.label}</span>
                      </NavLink>
                    </Menu.Item>
                  ) : null}
                  {item?.isSubmenu ? (
                    <SubMenu
                      title={
                        <>
                          {item?.icon}
                          <span className="">{item?.label}</span>
                        </>
                      }
                      className="siderSubMenu"
                    >
                      {item?.SubMenu.map((subItem) => {
                        return (
                          <Menu.Item key={subItem?.key}>
                            <NavLink to={subItem?.route}>
                              <span>{subItem?.label}</span>
                            </NavLink>
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  ) : null}
                </React.Fragment>
              );
            })}
          </Menu>
        </div>
        <div
          className="sider-toggler-main cursor-pointer bg-primary"
          data-testid="main-collapse-div-id"
          style={{ backgroundColor: `${colorPrimary}` }}
          onClick={() => dispatch(toggleSidebar(!isCollapse))}
        >
          {React.createElement(isCollapse ? RightOutlined : LeftOutlined, {
            className: "trigger",

            style: { fontSize: "16px", color: "#fff" },
          })}
        </div>
      </Sider>
    </>
  );
};

export default SideBar;
