import { get, post, put, del } from "../../helpers/apiWrapper";

// POST API for createParent
export const createParent = async (userData) => {
  const config = {
    data: userData,
  };
  return await post(`/user/create-user-profile`, config);
};

export const createChild = async (childData) => {
  const config = {
    data: childData,
  };
  return await post(`/child/create`, config);
};

// patch API for Edit USer
export const editParent = async ({ formData, data }) => {
  const config = {
    data: formData,
  };
  return await put(`/employee/edit/${data?.id}`, config);
};

// get API for get all user
export const getAllParent = async (data) => {
  const config = {
    params: data,
  };
  return await get(`/user/all`, config);
};

// delete Employee
export const deleteParent = async (data) => {
  const config = {
    data: data,
  };
  return await put(`/user/${data?.id}`, config);
};
