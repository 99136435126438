import { get, post, put, del } from "../../helpers/apiWrapper";

// POST API for createUser
export const createEmployee = async (userData) => {
  const config = {
    data: userData,
  };
  return await post(`/employee/create`, config);
};

// patch API for Edit USer
export const editEmployee = async ({ formData, data }) => {
  const config = {
    data: formData,
  };
  return await put(`/employee/edit/${data?.id}`, config);
};

// get API for get all user
export const getAllEmployee = async (data) => {
  const config = {
    params: data,
  };
  return await get(`/employee`, config);
};

// delete Employee
export const deleteEmployee = async (data) => {
  return await del(`/employee/delete/${data?.id}`);
};
